<template>
  <v-container>
    <v-list subheader two-line>
      <v-card-text class="title font-weight-bold">Eigene Apps</v-card-text>

      <v-layout row wrap>
        <v-flex xs12>
          <v-layout v-if="installedList">
            <v-flex xs6>
              <v-subheader class="body-2 font-weight-medium"
              >Bezeichnung
              </v-subheader
              >
            </v-flex>
            <v-flex xs4>
              <v-subheader class="body-2 font-weight-medium"
              >Eingestellte Version
              </v-subheader
              >
            </v-flex>
            <v-flex xs2>
              <v-subheader class="body-2 font-weight-medium"
              >Installierte Version
              </v-subheader
              >
            </v-flex>
            <v-flex class="pl-4" xs2>
              <v-subheader class="body-2 font-weight-medium pr-4"
              >Vergleich
              </v-subheader
              >
            </v-flex>
          </v-layout>

          <v-layout v-if="!installedList">
            <v-flex xs9>
              <v-subheader class="body-2 font-weight-medium"
              >Bezeichnung
              </v-subheader
              >
            </v-flex>
            <v-flex class="mr-4" xs3>
              <v-subheader class="body-2 font-weight-medium"
              >Eingestellte Version
              </v-subheader
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-divider/>
      <app-permission-tile
        v-for="app in apps"
        :key="app.id"
        :app="app"
        :disabled="disabled"
        :editedInstallList="editedInstallList"
        :installList="installList"
        :installedList="installedList"
        class="pt-1"
      />
    </v-list>

    <v-list subheader two-line>
      <v-card-text class="title font-weight-bold">Externe Apps</v-card-text>
      <v-layout row wrap>
        <v-flex xs12>
          <v-layout>
            <v-flex xs12>
              <v-card-text>Bezeichnung</v-card-text>
            </v-flex>
            <v-flex class="pr-4">
              <v-card-text>Erlaubt</v-card-text>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider/>

      <external-app-permission-tile
        v-for="app in externalApps"
        :key="app.external_id"
        :app="app"
        :disabled="disabled"
        :externalWhiteList="externalWhiteList"
      />
    </v-list>
  </v-container>
</template>

<script>
import appApi from '@/api/apps'
import AppPermissionTile from './AppPermissionTile'
import ExternalAppPermissionTile from './ExternalAppPermissionTile'

export default {
  name: 'permission-list',
  components: {
    ExternalAppPermissionTile,
    AppPermissionTile
  },
  props: {
    installList: Array,
    editedInstallList: Array,
    externalWhiteList: Array,
    installedList: Array,
    serialnumber: String,
    apps: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      externalApps: [],
    }
  },
  mounted () {
    appApi.getAllExternal().then((a) => {
      this.externalApps = a
    })
  },
}
</script>
