<template>
  <v-form v-if="response">
    <v-layout wrap>
      <v-flex xs2>
        <v-avatar size="80">
          <img :src="response.icon"/>
        </v-avatar>
      </v-flex>
      <v-flex xs10>
        <v-text-field v-model="response.name" label="Name"></v-text-field>
        <v-text-field
          v-model="response.packagename"
          disabled
          label="Paket"
        ></v-text-field>
      </v-flex>

      <v-flex xs5>
        <v-text-field
          v-model="response.version_name"
          disabled
          label="Version"
        ></v-text-field>
      </v-flex>
      <v-flex xs1></v-flex>
      <v-flex xs5>
        <v-text-field
          v-model="response.version_code"
          disabled
          label="Code"
        ></v-text-field>
      </v-flex>
      <v-flex xs1></v-flex>
      <v-flex
        v-if="response.overwrite"
        :class="shakeText ? 'shaking' : ''"
        xs12
      >
        <v-checkbox v-model="confirmOverwrite" class="red--text" color="red">
          <template v-slot:label>
            <div class="red--text">
              Dieser Upload überschreibt ein schon existierendes Paket mit
              gleicher Version. Ich bin mir dessen bewusst und bestätige diese
              Aktion
            </div>
          </template>
        </v-checkbox>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
export default {
  name: 'temp-app-form',
  data () {
    return {
      confirmOverwrite: false,
      shakeText: false,
    }
  },
  props: ['response'],
  methods: {
    validate () {
      if (this.response.overwrite && !this.confirmOverwrite) {
        this.shakeText = true
        setTimeout(() => {
          this.shakeText = false
        }, 1000)
        return false
      } else {
        return true
      }
    },
  },
}
</script>

<style scoped>
.shaking {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-2px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(4px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-8px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(8px, 0, 0);
  }
}
</style>
