import base from './base'

export default {
  getAll () {
    return base.request('maintenances', 'GET')
  },

  create (data) {
    return base.request(`maintenance`, 'POST', data)
  },

  edit (data) {
    return base.request(`maintenance`, 'PUT', data)
  },

  delete (data) {
    return base.request(`maintenance/delete`, 'DELETE', data)
  },
}
