<template>
  <v-dialog
    :value="state.currentQuestion !== null"
    max-width="290"
    @input="dialogInput"
  >
    <v-card>
      <v-card-text class="pt-3">
        {{ state.currentQuestion }}
      </v-card-text>

      <v-card-text v-if="state.currentNote" class="pt-3 primary--text">
        {{ state.currentNote }}
      </v-card-text>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          v-for="(answer, idx) in state.currentAnswers"
          :key="answer"
          color="primary secondary--text"
          text
          @click="chooseAnswer(idx)"
        >
          {{ answer }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QuestionStore from '@/stores/QuestionStore'

export default {
  name: 'question-dialog',
  data () {
    return {
      state: QuestionStore.state,
    }
  },
  methods: {
    chooseAnswer (idx) {
      QuestionStore.resolveQuestion(idx)
    },

    dialogInput () {
      QuestionStore.resolveQuestion(this.state.cancelIndex)
    },
  },
}
</script>
