<template>
  <v-layout row wrap>
    <v-flex xs12>
      <v-list-item v-if="installedList">
        <v-flex xs5>
          <v-layout>
            <v-list-item-avatar>
              <v-icon v-if="!app.icon" class="primary white--text"
              >fa-cloud
              </v-icon
              >
              <img v-if="app.icon" :src="app.icon"/>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="body-1">{{
                  app.name
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="body-2 font-weight-medium">{{
                  app.packagename
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-select
            v-model="selectedVersionCode"
            :disabled="disabled"
            :items="versions"
            class="pt-4 align-center"
            dense
            item-text="displayTitle"
            item-value="version_code"
          ></v-select>
        </v-flex>

        <v-flex xs3>
          <v-layout justify-center>
            <v-list-item-action-text class="title">
              {{ this.installedVersionName }}
            </v-list-item-action-text>
          </v-layout>
        </v-flex>

        <v-flex xs1>
          <v-list-item-avatar>
            <img
              v-if="this.matchingVersions"
              alt="ic_positive"
              src="../../assets/ic_positiv.png"
            />
            <img
              v-if="!this.matchingVersions"
              alt="ic_negative"
              src="../../assets/ic_negativ.png"
            />
          </v-list-item-avatar>
        </v-flex>
      </v-list-item>
      <v-list-item v-if="!installedList">
        <v-flex xs9>
          <v-layout>
            <v-list-item-avatar>
              <v-icon v-if="!app.icon" class="primary white--text"
              >fa-cloud
              </v-icon
              >
              <img v-if="app.icon" :src="app.icon"/>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="body-1">{{
                  app.name
                }}
              </v-list-item-title>
              <v-list-item-subtitle class="body-2 font-weight-medium">{{
                  app.packagename
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-layout>
        </v-flex>

        <v-flex xs3>
          <v-layout justify-center>
            <v-select
              v-model="selectedVersionCode"
              :disabled="disabled"
              :items="versions"
              class="pt-4 align-center"
              dense
              item-text="displayTitle"
              item-value="version_code"
            ></v-select>
          </v-layout>
        </v-flex>
      </v-list-item>
    </v-flex>
  </v-layout>
</template>
<script>
export default {
  name: 'app-permission-tile',
  props: {
    app: {},
    installList: Array,
    editedInstallList: Array,
    installedList: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      selectedVersionCode: this.emptyVersion,
    }
  },
  computed: {
    versions () {
      let none = []
      if (this.app.packagename !== 'de.lottobayern.service.terminal.desktop') {
        none.push(this.emptyVersion())
      }
      let v = this.app.versions.map((v) => {
        v.displayTitle = `${v.version_name} - ${v.version_code}`
        return v
      })
      none.push(...v)
      return none
    },
    installedVersion () {
      if (this.installedList) {
        const matching = this.installedList.filter((element) => {
          if (element.packagename === this.app.packagename) {
            return element
          }
        })
        if (matching.length > 0) {
          return matching[0]
        }
      }
      return null
    },
    installedVersionName () {
      return this.installedVersion
        ? `${this.installedVersion.version_name} - ${this.installedVersion.version_code}`
        : '--'
    },
    matchingVersions () {
      if (this.selectedVersionCode !== null) {
        if (typeof this.selectedVersionCode === 'object') {
          return (
            this.serialized(this.selectedVersionCode).version_code ===
            (this.installedVersion
              ? this.installedVersion.version_code
              : this.emptyVersion().version_code)
          )
        } else {
          return (
            this.selectedVersionCode ===
            (this.installedVersion
              ? this.installedVersion.version_code
              : this.emptyVersion().version_code)
          )
        }
      } else {
        return this.selectedVersionCode === this.installedVersion
      }
    },
  },
  methods: {
    emptyVersion () {
      return {
        version_code: null,
        displayTitle: 'Keine',
      }
    },
    updateSelected () {
      if (this.installList) {
        const matching = this.installList.filter((element) => {
          if (element.packagename === this.app.packagename) {
            return element
          }
        })
        if (matching.length > 0) {
          this.selectedVersionCode = matching[0].version_code
        } else {
          this.selectedVersionCode = this.emptyVersion()
        }
        return null
      }
    },
    serialized (object) {
      return {
        version_code: object.version_code,
        displayTitle: object.displayTitle,
      }
    },
  },
  watch: {
    selectedVersionCode (newVal) {
      this.editedInstallList.filter((element) => {
        if (element.app === this.app.packagename) {
          this.editedInstallList.splice(
            this.editedInstallList.indexOf(element),
            1
          )
        }
      })
      this.editedInstallList.push({
        app: this.app.packagename,
        version: newVal,
      })
    },
  },
  mounted () {
    this.updateSelected()
  },
}
</script>

<style scoped></style>
