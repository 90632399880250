<template>
  <form-dialog
    :can-save="userCanWrite"
    :title="title"
    :value="value"
    @input="close($event)"
    @save="save()"
  >
    <device-details
      :key="groupDeviceDetails ? groupDeviceDetails.device_id : Date.now()"
      v-model="groupDeviceDialog"
      :device="groupDeviceDetails"
    />
    <v-layout class="pt-4" style="padding-left: 10%; padding-right: 10%">
      <v-card color="#F5F5F5" elevation="0" style="width: 100%">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="#E32119"
          fixed-tabs
        >
          <v-layout class="justify-space-between">
            <v-tab v-for="item in items" :key="item" style="width: 50%">
              {{ item }}
            </v-tab>
          </v-layout>
        </v-tabs>
        <v-divider/>
      </v-card>
    </v-layout>

    <v-layout style="padding-left: 10%; padding-right: 10%">
      <v-flex>
        <v-card class="mt-4">
          <v-tabs-items v-model="tab">
            <v-tab-item :key="items[0]" style="width: 90%">
              <v-form>
                <v-container fluid grid-list-xl style="width: 90%">
                  <v-layout wrap>
                    <v-flex sm12>
                      <h3>Stammdaten</h3>
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-text-field
                        v-model="name"
                        :disabled="
                          (mutableGroup && mutableGroup.is_default) ||
                          !userCanWrite
                        "
                        label="Name"
                        required
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-tab-item>
            <v-tab-item :key="items[1]">
              <v-form>
                <v-container fluid grid-list-xl style="width: 90%">
                  <v-layout wrap>
                    <v-flex sm12>
                      <h3>Einstellungen</h3>
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-subheader class="pl-0">Helligkeit</v-subheader>
                      <v-slider
                        v-model="settings.brightness"
                        :disabled="!userCanWrite"
                        max="1"
                        min="0"
                        step="0.05"
                        thumb-label="always"
                      />
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-subheader class="pl-0">Lautstärke</v-subheader>
                      <v-slider
                        v-model="settings.volume"
                        :disabled="!userCanWrite"
                        max="1"
                        min="0"
                        step="0.05"
                        thumb-label="always"
                      />
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-text-field
                        v-if="userCanWrite"
                        v-model="settings.password"
                        :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                        :type="showPassword ? 'text' : 'password'"
                        label="Passwort"
                        required
                        @click:append="requestShowPassword()"
                      />
                    </v-flex>
                    <!-- hide password field for users, whoes permission is not admin -->
                    <v-flex v-if="!userCanWrite" sm12 xs12>
                      <v-text-field
                        v-model="settings.refresh_minutes"
                        :disabled="!userCanWrite"
                        :rules="intervalRules"
                        label="Abfrageintervall (Minuten)"
                        required
                      >
                      </v-text-field>
                    </v-flex>
                    <!-- otherwise show password field -->
                    <v-flex v-else sm6 xs12>
                      <v-text-field
                        v-model="settings.refresh_minutes"
                        :disabled="!userCanWrite"
                        :rules="intervalRules"
                        label="Abfrageintervall (Minuten)"
                        required
                      >
                      </v-text-field>
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-checkbox
                        v-model="settings.location_service"
                        :disabled="!userCanWrite"
                        label="Ortungsdienste"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-tab-item>

            <v-tab-item :key="items[2]">
              <v-form>
                <v-container
                  v-if="mutableGroup"
                  fluid
                  grid-list-xl
                  style="width: 90%"
                >
                  <v-layout wrap>
                    <v-flex sm12>
                      <h3>Apps</h3>
                    </v-flex>
                  </v-layout>
                </v-container>

                <permission-list
                  :apps="apps"
                  :disabled="!userCanWrite"
                  :editedInstallList="editedInstallList"
                  :externalWhiteList="externalWhiteList"
                  :installList="installList"
                />
              </v-form>
            </v-tab-item>

            <v-tab-item :key="items[3]">
              <v-form>
                <v-container
                  v-if="mutableGroup"
                  fluid
                  grid-list-xl
                  style="width: 90%"
                >
                  <v-layout wrap>
                    <v-flex sm12>
                      <h3>Geräte</h3>
                    </v-flex>
                  </v-layout>
                </v-container>

                <v-data-table
                  :footer-props="footerProps"
                  :headers="devicesTableHeaders"
                  :height="wHeight * 0.7"
                  :items="mutableGroup ? mutableGroup.devices : []"
                  :options.sync="tableOptions"
                  class="elevation-1"
                  fixed-header
                  @pagination="paginationChanged"
                >
                  <template v-slot:item="row">
                    <device-list-tile
                      :device="row.item"
                      :show-trash="userCanWrite"
                      @click="showDevice"
                    />
                  </template>
                </v-data-table>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
    <v-container v-if="!mutableGroup">
      <span
      >Berechtigungen können erst festgelegt werden, nachdem Gruppe angelegt
        wurde</span
      >
    </v-container>
  </form-dialog>
</template>

<script>
import groupsApi from '../api/groups'
import FormDialog from '../components/FormDialog'
import PermissionList from '../components/Devices/PermissionList'
import ErrorStore from '@/stores/ErrorStore'
import DeviceListTile from '../components/Devices/DeviceListTile'
import AuthStore from '@/stores/AuthStore'
import appApi from '@/api/apps'
import DeviceDetails from './DeviceDetails'
import deviceApi from '@/api/device'

export default {
  name: 'group-details',
  components: {
    DeviceListTile,
    PermissionList,
    FormDialog,
    DeviceDetails
  },
  data () {
    return {
      tab: null,
      mutableGroup: null,
      installList: null,
      externalWhiteList: null,
      editedInstallList: [],
      groupDeviceDialog: false,
      groupDeviceDetails: null,
      apps: [],
      items: ['Stammdaten', 'Einstellung', 'Apps', 'Geräte'],
      devicesTableHeaders: [
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: 'Seriennummer',
          value: 'serialnumber'
        },
        {
          text: 'Annahmestelle',
          value: 'annahmestelle.annahmestellennr'
        },
        {
          text: 'IP-Adresse',
          value: 'ip'
        },
      ],
      intervalRules: [
        (v) => !!v || 'Intervall wird benötigt',
        (v) => /^[0-9]+$/.test(v) || 'Bitte nur ganze Zahlen eingeben',
      ],
      settings: {},
      name: '',
      showPassword: false,
      footerProps: {
        'items-per-page-options': [5, 10, 15, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
      },
      wHeight: 0,
    }
  },
  props: {
    value: Boolean,
    group: {},
  },
  computed: {
    title () {
      if (this.mutableGroup) {
        return this.mutableGroup.name
      }
      return 'Neue Gruppe'
    },
    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
  },
  watch: {
    group () {
      this.updateGroup()
    },
    windowHeight (height) {
      this.wHeight = height
    },
    apps (allApps) {
      if (
        this.installList.length === 0 &&
        this.editedInstallList.length === 0 &&
        allApps.length > 0
      ) {
        this.editedInstallList = allApps.map((app) => {
          return {
            app: app.packagename,
            version: {
              version_code: null,
              displayTitle: 'Keine',
            },
          }
        })
      }
    },
    installList (newInstallList) {
      if (this.editedInstallList.length === 0 && newInstallList.length > 0) {
        this.editedInstallList = newInstallList.map((app) => {
          return {
            app: app.packagename,
            version: app.version_code,
          }
        })
      }
    },
  },
  mounted () {
    appApi.getAll().then((a) => {
      this.apps = a
      this.updateGroup()
    })
    this.wHeight = this.$vuetify.breakpoint.height
  },
  methods: {
    itemsPerPage () {
      return parseInt(localStorage.getItem('groupDetailsItemsPerPage')) || 15
    },
    paginationChanged (pagination) {
      localStorage.setItem('groupDetailsItemsPerPage', pagination.itemsPerPage)
    },
    updateGroup () {
      if (this.group) {
        this.mutableGroup = this.group
        this.installList = this.mutableGroup.apps.install
        this.externalWhiteList = this.mutableGroup.apps.whitelist
        this.name = this.mutableGroup.name
        this.settings = { ...this.mutableGroup.settings }
      } else {
        this.name = ''
        this.installList = []
        this.externalWhiteList = []
        this.settings = {}
        groupsApi.getDefault().then((g) => {
          this.settings = g.settings
        })
      }
    },
    save () {
      const submitSettings = {
        name: this.name,
        brightness: this.settings.brightness,
        volume: this.settings.volume,
        password: this.settings.password,
        refresh_minutes: this.settings.refresh_minutes,
        location_service: this.settings.location_service,
      }
      if (this.mutableGroup) {
        this.updateGroupSettings(this.mutableGroup.group_id, submitSettings)
      } else {
        this.createGroupWithSettings(submitSettings, () => {
          this.close()
        })
      }
    },
    async updateGroupSettings (group_id, settings) {
      groupsApi
        .update(group_id, settings)
        .then((g) => {
          return groupsApi.updatePermissions(group_id, this.editedInstallList)
        })
        .then(() => {
          return groupsApi.updateExternalPermissions(
            group_id,
            this.externalWhiteList
          )
        })
        .then(() => {
          return groupsApi.getDetails(group_id)
        })
        .then((g) => {
          ErrorStore.showMessage('Änderungen gespeichert', 'Erfolg')
          this.mutableGroup = g
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
    async createGroupWithSettings (settings, callback) {
      groupsApi
        .create(settings)
        .then((g) => {
          const group_id = g.group_id
          ErrorStore.showMessage('Gruppe angelegt', 'Erfolg')
          this.updateGroupSettings(group_id, settings).then(callback())
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
    showDevice (device) {
      deviceApi.getDetails(device.device_id).then((details) => {
        this.groupDeviceDetails = details
        this.groupDeviceDialog = true
      })
    },
    requestShowPassword () {
      if (this.userCanWrite) {
        this.showPassword = !this.showPassword
      }
    },
    close (event = null) {
      this.$emit('input', event)
      this.$emit('reload')
    },
  },
}
</script>

<style scoped></style>
