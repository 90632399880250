<template>
  <v-list-item>
    <v-list-item-avatar>
      <v-icon class="primary white--text">fa-mobile</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title>{{ app.packagename }}</v-list-item-title>
      <v-list-item-subtitle
      >Version code: {{ app.version_code }} / Version name:
        {{ app.version_name }}
      </v-list-item-subtitle
      >
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: 'installed-apps-tile',
  props: {
    app: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
