<template>
  <div>
    <group-details
      :key="groupDetails ? groupDetails.group_id : Date.now()"
      v-model="detailsDialog"
      :group="groupDetails"
      @reload="reload"
    ></group-details>
    <v-sheet class="pa-3 primary lighten-2">
      <v-text-field
        v-model="search"
        clear-icon="mdi-close-circle-outline"
        clearable
        dark
        flat
        hide-details
        label="Gruppen durchsuchen"
        prepend-inner-icon="fa-search"
        solo-inverted
      ></v-text-field>
    </v-sheet>
    <v-container fluid>
      <v-list subheader two-line>
        <v-subheader inset>Gruppen</v-subheader>

        <group-list-tile
          v-for="item in displayGroups"
          :key="item.group_id"
          :item="item"
          :show-trash="userCanWrite"
          @click="showDetails"
          @delete="deleteGroup"
        />
      </v-list>
      <v-btn
        v-if="userCanWrite"
        bottom
        color="primary"
        dark
        fab
        fixed
        right
        style="margin-bottom: 50pt"
        @click="createGroup"
      >
        <v-icon>fa-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import groupsApi from '@/api/groups'
// import UserDetails from './UserDetails'
import GroupListTile from '../components/Groups/GroupListTile'
import GroupDetails from './GroupDetails'
import QuestionStore from '@/stores/QuestionStore'
import AuthStore from '@/stores/AuthStore'
import ErrorStore from '@/stores/ErrorStore'

export default {
  name: 'all-groups',
  components: {
    GroupDetails,
    GroupListTile,
    // UserDetails,
  },
  data () {
    return {
      groups: [],
      search: '',
      detailsDialog: false,
      groupDetails: null,
    }
  },
  methods: {
    showDetails (group) {
      this.showDetailsForId(group.group_id)
    },
    showDetailsForId (groupId) {
      groupsApi.getDetails(groupId).then((g) => {
        this.groupDetails = g
        this.detailsDialog = true
      })
    },
    createGroup () {
      this.groupDetails = null
      this.detailsDialog = true
    },
    deleteGroup (group) {
      QuestionStore.poseQuestion(
        `Gruppe ${group.name} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein']
      ).then((answer) => {
        if (answer === 0) {
          this.reallyDeleteGroup(group)
        }
      })
    },
    reallyDeleteGroup (group) {
      groupsApi
        .delete(group.group_id)
        .then(() => {
          this.reload()
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
    reload () {
      return groupsApi.getAll().then((a) => {
        this.groups = a
        this.groups.sort(function (x, y) {
          return x.is_default ? -1 : y.is_default ? 1 : 0
        })
      })
    },
  },

  mounted () {
    this.reload().then(() => {
      if (this.$route.params.id) {
        this.showDetailsForId(this.$route.params.id)
      }
    })
  },
  watch: {
    /* When the user hits "Speichern" there will be 2 nested Scrollbars shown on Chrome browser.
     To stop this behavior we need to set this property to hidden, once the dialog shows up */
    detailsDialog: function (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
  },
  computed: {
    displayGroups () {
      if (this.search === '') {
        return this.groups
      }
      return this.groups.filter((a) => {
        return a.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },

    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-icon {
  height: auto;
}
</style>
