export default {
  state: {
    currentQuestion: null,
    currentAnswers: null,
    cancelIndex: null,
    currentNote: null,
    _questionResolve: null,
  },
  poseQuestion (text, answers, cancelIndex, note) {
    this.state.currentQuestion = text
    this.state.currentAnswers = answers
    this.state.cancelIndex = cancelIndex !== undefined ? cancelIndex : 1
    this.state.currentNote = note !== undefined ? note : null
    return new Promise((resolve) => {
      this.state._questionResolve = resolve
    })
  },
  resolveQuestion (answer) {
    this.state._questionResolve(answer)
    this.state.currentQuestion = null
    this.state.currentAnswers = null
  },
}
