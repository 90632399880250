import errorMessages from '@/api/errors'

export default {
  state: {
    currentError: null,
    currentTitle: null,
  },
  showMessage (message, title) {
    this.state.currentError = message
    this.state.currentTitle = title
  },
  showErrorMessage (message, title) {
    this.state.currentError = message
    this.state.currentTitle = title || 'Fehler'
  },
  showError (objectOrMessageOrPromise) {
    this.state.currentTitle = 'Fehler'
    // Error as a promise has to be resolved first
    if (typeof objectOrMessageOrPromise.then === 'function') {
      objectOrMessageOrPromise.then((err) => {
        this.showError(err)
      })
      return
    }
    if (
      objectOrMessageOrPromise.hasOwnProperty('message') &&
      objectOrMessageOrPromise.message.hasOwnProperty('code')
    ) {
      const displayMessage =
        errorMessages[objectOrMessageOrPromise.message.code]
      if (displayMessage) {
        this.state.currentError = displayMessage
      } else {
        this.state.currentError = objectOrMessageOrPromise.message.description
      }
    }
  },
  clearError () {
    this.state.currentError = null
  },
}
