<template>
  <v-menu v-model="display"
          :close-on-content-click="false"
          :max-width="dialogWidth"
          :width="dialogWidth"
          bottom
          offset-y
          transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-row no-gutters>
        <v-col>
          <v-text-field
            slot="activator"
            :value="compColor"
            :label="label"
            class="my-2"
            color="primary"
            readonly
            hide-details
            v-on="on"
          >
          </v-text-field>
        </v-col>
      </v-row>
    </template>

    <v-card>
      <v-color-picker
        :value="outputColor"
        elevation="8"
        mode="hexa"
        show-swatches
        swatches-max-height="250px"
        @update:color="updateColor($event)"
      ></v-color-picker>
      <v-divider></v-divider>
      <v-card-actions>
        <slot :parent="this" name="actions">
          <v-spacer></v-spacer>
          <v-btn
            color="buttonOrInput"
            text
            @click.native="clearHandler"
          >
            {{ cancelText }}
          </v-btn>
          <v-btn
            class="primary--text"
            color="secondary"
            @click="okHandler"
          >
            {{ okText }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'ColorPickerMenu',
  props: {
    dialogWidth: {
      type: Number,
      default: 300
    },
    okText: {
      type: String,
      required: false,
      default: 'Bestätigen'
    },
    cancelText: {
      type: String,
      required: false,
      default: 'Zurücksetzen'
    },
    color: {
      type: [String, Object],
      required: false,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      display: false,
      outputColor: null,
      applyColorOnDismiss: false
    }
  },
  computed: {
    compColor () {
      // color string here is the default if color picker v-model is set to unparseable color
      if (this.outputColor) {
        if (this.outputColor.hex) {
          return this.outputColor.hex
        } else {
          return this.outputColor
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    showColor () {
      if (typeof this.outputColor === 'string') {
        return this.outputColor
      } else {
        return JSON.stringify(
          Object.keys(this.compColor).reduce((color, key) => {
            color[key] = Number(this.compColor[key].toFixed(2))
            return color
          }, {}),
          null,
          2
        )
      }
    },
    updateColor (color) {
      if (color.hexa === '#FF0000FF') {
        this.outputColor = ''
      } else {
        this.outputColor = color.hex
      }
    },
    init () {
      this.outputColor = this.color && this.color.length > 0 ? this.color : null
    },
    okHandler () {
      this.resetPicker()
      if (this.outputColor && this.outputColor.length > 7) {
        // Don't support alpha valued colors
        this.outputColor = this.outputColor.substring(0, this.outputColor.length - 2)
      }
      this.$emit('confirm', this.outputColor)
    },
    clearHandler () {
      this.resetPicker()
      this.outputColor = null
      this.$emit('cancel')
    },
    resetPicker () {
      this.display = false
    }
  },
  watch: {
    color () {
      this.init()
    },
    display (isShown) {
      if (!isShown) {
        if (this.applyColorOnDismiss) {
          this.okHandler()
        }
      }
    }
  },
  created () {
    this.init()
  }
}
</script>
