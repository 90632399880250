import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/Login'
import AllDevices from './views/AllDevices'
import AuthStore from '@/stores/AuthStore'
import AllUsers from './views/AllUsers'
import AllApps from './views/AllApps'
import AllExternalApps from './views/AllExternalApps'
import AllGroups from './views/AllGroups'
import DeviceReports from './views/DeviceReports'
import AllReports from '@/views/AllReports'
import AllLogs from '@/views/AllLogs'
import bayernmillionen from '@/views/Bayernmillionen'
import AllDocuments from '@/views/AllDocuments'
import AllLots from '@/views/AllLots'
import DeviceAnalytics from '@/views/DeviceAnalytics'
import DeviceLogs from '@/views/DeviceLogs'
import sessionStore from './stores/AuthStore'
import themeUtil from '@/utils/themeUtil'
import EJSwitch from '@/views/EJSwitch'
import Maintenance from '@/views/Maintenance'

Vue.use(Router)
const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/device',
    name: 'devices',
    component: AllDevices,
  },
  {
    path: '/device/:id',
    name: 'annahmestellen_details',
    component: AllDevices,
  },
  {
    path: '/device/:id/reports',
    name: 'device_reports',
    component: DeviceReports,
  },
  {
    path: '/users',
    name: 'users',
    component: AllUsers,
  },
  {
    path: '/groups',
    name: 'groups',
    component: AllGroups,
  },
  {
    path: '/groups/:id',
    name: 'group_details',
    component: AllGroups,
  },
  {
    path: '/apps',
    name: 'apps',
    component: AllApps,
  },
  {
    path: '/external_apps',
    name: 'external_apps',
    component: AllExternalApps,
  },
  {
    path: '/reports',
    name: 'reports',
    component: AllReports,
  },
  {
    path: '/logs',
    name: 'logs',
    component: AllLogs,
  },
  {
    path: '/bayernmillionen',
    name: 'bayernmillionen',
    component: bayernmillionen,
  },
  {
    path: '/docs',
    name: 'docs',
    component: AllDocuments,
  },
  {
    path: '/lots',
    name: 'lots',
    component: AllLots,
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: DeviceAnalytics,
  },
  {
    path: '/device_logs',
    name: 'Logs',
    component: DeviceLogs,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },
  {
    path: '/logout',
    name: 'logout',
  },
]

if (themeUtil.isDevOrTestTheme() && AuthStore.state.isAdmin) {
  routes.push({
    path: '/ej-switch',
    name: 'EJ-Umstellung',
    component: EJSwitch,
  })
}
const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: routes,
})

router.beforeEach((to, from, next) => {
  const publicRoutes = ['login']
  const editorRoutes = ['docs', 'users', 'Maintenance']
  if (to.name === 'logout') {
    sessionStore.commit('logout')
  }
  if (
    publicRoutes.includes(to.name) === false &&
    [null, undefined].includes(AuthStore.state.apiToken)
  ) {
    next('/login')
  } else if (to.name === null) {
    // Add default routes for empty paths for logged in / out states to fix blank pages being shown
    if (AuthStore.state.user === null) {
      next('/login')
    } else {
      next('/device')
    }
  } else {
    if (
      AuthStore.state.isEditor() &&
      editorRoutes.includes(to.name) === false
    ) {
      next(`/${editorRoutes[0]}`)
    } else {
      next()
    }
  }
})

export default router
