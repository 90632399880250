<template>
  <div>
    <device-details
      v-model="detailsDialog"
      :device="deviceDetails"
      @reload="this.reload"
    />
    <v-sheet class="pa-3 primary lighten-2">
      <v-text-field
        v-model="search"
        clear-icon="mdi-close-circle-outline"
        clearable
        dark
        flat
        hide-details
        label="Annahmestellen durchsuchen"
        prepend-inner-icon="fa-search"
        solo-inverted
      ></v-text-field>
      <v-select
        v-model="filter"
        :items="bindFilters"
        dark
        item-text="name"
        item-value="filter"
      ></v-select>
    </v-sheet>
    <v-container fluid>
      <v-card>
        <v-container>
          <v-btn class="primary" @click="downloadExport"
          >CSV Export
            <v-icon right>fa-file-export</v-icon>
          </v-btn>
        </v-container>
      </v-card>
    </v-container>
    <v-container fluid>
      <v-card>
        <v-container>
          Geräte aus der Gruppe "Standard" werden nicht mit aufgelistet
        </v-container>
      </v-card>

      <v-data-table
        :footer-props="footerProps"
        :headers="tableHeaders"
        :height="wHeight * 0.5"
        :items="displayDevices"
        :loading="loading"
        :options.sync="tableOptions"
        class="elevation-1"
        fixed-header
        loading-text="Daten werden geladen ..."
        @pagination="paginationChanged"
      >
        <template v-slot:item="row">
          <device-list-row
            :device="row.item"
            :show-trash="false"
            @click="showDetails"
            @action-click="showReports"
          />
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import analyticsApi from '@/api/analytics'
import deviceApi from '@/api/device'
import DeviceDetails from './DeviceDetails'
import AuthStore from '@/stores/AuthStore'
import DeviceListRow from '@/components/Devices/DeviceListRow'

export default {
  name: 'Analytics',
  components: {
    DeviceListRow,
    DeviceDetails
  },
  data () {
    return {
      devices: [],
      search: '',
      detailsDialog: false,
      deviceDetails: null,
      fab: false,
      groups: [],
      filter: 'wrong_installed_apps',
      bindFilters: [
        {
          filter: 'wrong_installed_apps',
          name: 'Tablets ohne Updates',
        },
        {
          filter: 'no_installed_apps',
          name: 'Keine installierten Apps bekannt',
        },
        {
          filter: 'not_online',
          name: 'Abfrageintervall überschritten',
        },
      ],
      footerProps: {
        'items-per-page-options': [20, 50, 100, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
        sortBy: ['customAnnahmestellennr'],
      },
      wHeight: 0,
      loading: false,
    }
  },
  methods: {
    showDetailsForId (id) {
      deviceApi.getDetails(id).then((details) => {
        this.deviceDetails = details
        this.detailsDialog = true
      })
    },
    showDetails (device) {
      this.showDetailsForId(device.device_id)
    },
    showReports (device) {
      this.$router.push(`/device/${device.device_id}/reports`)
    },

    reload () {
      this.loading = true
      analyticsApi.getAnalytics(this.filter).then((a) => {
        this.devices = a
        this.loading = false
      })
    },

    downloadExport () {
      const deviceIds = this.displayDevices.map((d) => {
        return d.device_id
      })
      deviceApi
        .downloadExport(deviceIds)
        .then((r) => {
          return r.arrayBuffer()
        })
        .then((r) => {
          const url = window.URL.createObjectURL(new Blob([r]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Geräte.csv')
          document.body.appendChild(link)
          link.click()
        })
    },
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
  },
  mounted () {
    this.reload()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  watch: {
    /* When the user hits "Speichern" there will be 2 nested Scrollbars shown on Chrome browser.
           To stop this behavior we need to set this property to hidden, once the dialog shows up */
    detailsDialog: function (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },

    filter () {
      this.reload()
    },

    windowHeight (height) {
      this.wHeight = height
    },
  },
  computed: {
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    displayDevices () {
      let devices = this.devices
      if (this.search !== '') {
        const search = this.search.toLowerCase()
        devices = devices.filter((a) => {
          return (
            a.serialnumber.toLowerCase().includes(search) ||
            (a &&
              a.annahmestelle &&
              a.annahmestelle.annahmestellennr.toLowerCase().includes(search))
          )
        })
      }

      // Attach extra annahmestellennr property for custom sorting
      devices = devices.map((d) => {
        // Add a ridiculously lexicographically high value if AST is empty, so those get sorted to the end
        d.customAnnahmestellennr =
          d.annahmestelle &&
          d.annahmestelle.annahmestellennr &&
          d.annahmestelle.annahmestellennr.trim()
            ? d.annahmestelle.annahmestellennr
            : 'ZZZZZZZZZZZZZZZ'
        return d
      })

      return devices
    },

    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },

    tableHeaders () {
      return [
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: `Geräte (${this.displayDevices.length})`,
          value: 'serialnumber',
        },
        {
          text: 'Annahmestelle',
          value: 'customAnnahmestellennr'
        },
        {
          text: 'AST Zusatzinfo',
          value: 'ast_info'
        },
        {
          text: 'IP-Adresse',
          value: 'ip'
        },
        {
          text: 'Gruppe',
          value: 'group.name'
        },
        {
          text: 'Manuelle Öffnungszeiten',
          value: 'manual_opening_times'
        },
        {
          text: 'Letzte Anfrage',
          value: 'last_access'
        },
        {
          text: 'Absturzberichte',
          value: 'report_count'
        },
      ]
    },
  },
}
</script>

<style lang="scss" scoped>
.v-icon {
  height: auto;
}
</style>
