<template>
  <v-dialog :value="value" persistent width="500">
    <v-card background-color="#F5F5F5">
      <v-toolbar color="secondary">
        <v-btn color="primary" icon @click="cancel">
          <v-icon>fa-window-close</v-icon>
        </v-btn>
        <v-toolbar-title class="primary--text"
        >Dokument bearbeiten
        </v-toolbar-title
        >
      </v-toolbar>
      <v-container>
        <v-form style="margin-top: 20px">
          <v-text-field
            :value="this.edited_doc ? this.edited_doc.category : ''"
            filled
            label="Kategorie"
            readonly
          ></v-text-field>
          <v-text-field
            :value="this.edited_doc ? this.edited_doc.key : ''"
            filled
            label="Key"
            readonly
          ></v-text-field>
          <v-text-field
            :value="this.edited_doc ? this.edited_doc.title : ''"
            filled
            label="Titel"
            readonly
          ></v-text-field>
          <v-text-field
            v-model="publish_at"
            :hint="inputDateFormat"
            :placeholder="dateInputPlaceHolder"
            background-color="white"
            filled
            label="Verfügbar ab"
          ></v-text-field>
          <v-text-field
            v-model="publish_until"
            :hint="inputDateFormat"
            :placeholder="dateInputPlaceHolder"
            background-color="white"
            filled
            label="Verfügbar bis"
          ></v-text-field>
          <v-layout justify-center>
            <v-btn
              class="primary--text"
              color="secondary"
              large
              @click="submit"
            >
              Speichern
            </v-btn>
          </v-layout>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import docApi from '@/api/docs'
import ErrorStore from '@/stores/ErrorStore'

export default {
  name: 'doc-edit-dialog',
  props: ['edited_doc', 'value'],
  data () {
    return {
      dateInputPlaceHolder: 'dd.mm.yyyy - HH:MM',
      inputDateFormat: 'Format: dd.mm.yyyy - HH:MM',
      publish_at: null,
      publish_until: null,
    }
  },
  methods: {
    cancel () {
      this.publish_at = null
      this.publish_until = null
      this.$emit('close')
    },
    submit () {
      docApi
        .updateDoc(this.strippedSrc, this.publish_at, this.publish_until)
        .then((r) => {
          ErrorStore.showErrorMessage(
            'das Dokument wurde erfolgreich bearbeitet',
            'Erfolg'
          )
          this.cancel()
          this.$emit('reload')
        })
        .catch((err) => {
          ErrorStore.showError(err)
        })
    },
  },
  watch: {
    edited_doc (value) {
      if (value) {
        this.publish_at = value.publish_at
        this.publish_until = value.publish_until
      }
    },
  },
  computed: {
    strippedSrc () {
      const url = this.edited_doc.src
      return url.substr(url.indexOf('file/') + 5, url.length)
    },
  },
}
</script>

<style lang="scss" scoped></style>
