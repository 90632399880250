<template>
  <vue-dropzone
    id="dropzone"
    ref="myVueDropzone"
    :options="dropzoneOptions"
    @vdropzone-upload-progress="progressChanged"
    @vdropzone-success="uploadSuccess"
  ></vue-dropzone>
</template>
<script>
import VueDropzone from 'vue2-dropzone'
import AuthStore from '@/stores/AuthStore'

export default {
  name: 'file-upload-box',
  components: { VueDropzone },
  data () {
    return {}
  },
  computed: {
    dropzoneOptions () {
      return {
        url: this.url,
        thumbnailWidth: 150,
        headers: {
          'X-Auth-Token': AuthStore.state.apiToken,
        },
        paramName: this.paramName,
        timeout: 100000,
        accepted: this.fileTypes,
      }
    },
  },
  props: ['url', 'fileTypes', 'paramName'],
  methods: {
    uploadSuccess (file, response) {
      const elements = document.getElementsByClassName('dz-upload')
      const uploadProgress = elements[0]
      uploadProgress.setAttribute('style', `width: 100%`)
      const self = this
      setTimeout(function () {
        self.$emit('uploaded', response)
      }, 1000)
    },
    progressChanged (file, progress, bytesSent) {
      const elements = document.getElementsByClassName('dz-upload')
      const uploadProgress = elements[0]
      uploadProgress.setAttribute('style', `width: ${progress - 10}%`)
    },
  },
}
</script>
