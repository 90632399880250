import base from './base'

export default {
  getDeviceLogs (settings) {
    return base.request('device_logs', 'POST', settings)
  },
  getDeviceLogData (settings) {
    return base.request('device_log', 'POST', settings)
  },
}
