<template>
  <tr :key="log._id" style="cursor: pointer" @click="clicked">
    <td>{{ log.serialnr }}</td>
    <td>{{ log.client_ip }}</td>
    <td>{{ log.user_agent }}</td>
    <td>{{ log.type }}</td>
    <td>{{ createdAt }}</td>
  </tr>
</template>

<script>
import timeUtils from '@/utils/time'

export default {
  name: 'device-logs-row',
  props: ['log'],
  methods: {
    clicked () {
      this.$emit('click', this.log)
    },
  },
  computed: {
    createdAt () {
      return this.log ? timeUtils.formattedDate(this.log.created_at) : ''
    },
  },
}
</script>

<style scoped></style>
