<template>
  <tr :key="log.id" style="cursor: pointer">
    <td>
      <v-checkbox
        v-if="!log.on_the_fly && allowInteraction"
        v-model="checked"
        hide-details
        primary
        style="margin: auto 0"
        v-on:change="updateLogsList"
      />
    </td>
    <td>
      <v-list-item-avatar>
        <v-icon class="primary white--text"> fa-book</v-icon>
      </v-list-item-avatar>
    </td>
    <td>
      {{ log.description }}
    </td>
    <td class="text--primary">
      {{ log.serialnumber }}
    </td>
    <td>
      {{ createdAt }}
    </td>
  </tr>
</template>

<script>
import timeUtils from '@/utils/time'

export default {
  name: 'log-list-row',
  props: ['log', 'allowInteraction'],
  data () {
    return {
      checked: false,
    }
  },
  computed: {
    createdAt () {
      return timeUtils.formattedDate(this.log.created_at)
    },
  },
  methods: {
    updateLogsList () {
      this.$emit('updateList', this.checked, this.log)
    },
  },
}
</script>

<style scoped></style>
