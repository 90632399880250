<template>
  <v-container fluid>
    <v-layout class="pa-3" justify-end row wrap>
      <v-flex shrink>
        <v-btn
          v-if="logs2Delete.length > 0"
          color="primary"
          justify-end
          @click="confirmDelete"
        >({{ logs2Delete.length }}) Ausgewählte Löschen
        </v-btn>
      </v-flex>
    </v-layout>
    <v-data-table
      :footer-props="footerProps"
      :headers="logTableHeaders"
      :height="wHeight * 0.8"
      :items="logs"
      :loading="isLoading ? 'loading' : false"
      :options.sync="tableOptions"
      class="elevation-1"
      fixed-header
      loading-text="Daten werden geladen ..."
      @pagination="paginationChanged"
    >
      <template v-slot:item="props">
        <log-list-row
          :key="props.item.id"
          :allow-interaction="authState.hasWriteAccess()"
          :log="props.item"
          @updateList="updateList"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import miscApi from '@/api/misc'
import LogListRow from '@/components/Misc/LogListRow'
import QuestionStore from '@/stores/QuestionStore'
import AuthStore from '@/stores/AuthStore'

export default {
  name: 'all-logs',
  components: { LogListRow },
  data () {
    return {
      logTableHeaders: [
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: 'Info',
          value: 'description'
        },
        {
          text: 'Seriennummer',
          value: 'serialnumber'
        },
        {
          text: 'Datum',
          value: 'created_at'
        },
      ],
      logs: [],
      logs2Delete: [],
      authState: AuthStore.state,
      footerProps: {
        'items-per-page-options': [20, 50, 100, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
        sortBy: ['annahmestellennummer'],
      },
      loading: true,
      wHeight: 0,
    }
  },
  mounted () {
    this.refresh()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  methods: {
    refresh () {
      miscApi.getLogs().then((l) => {
        this.logs = l
        this.loading = false
      })
    },
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
    updateList (checked, log) {
      if (checked) {
        this.logs2Delete.push(log)
      } else {
        var removeIndex = this.logs2Delete.indexOf(log)
        this.logs2Delete.splice(removeIndex, 1)
      }
    },
    confirmDelete () {
      QuestionStore.poseQuestion(
        'Ausgewählte Fehlerlogs unwiderruflich entfernen?',
        ['Löschen', 'Abbrechen']
      ).then((answer) => {
        if (answer === 0) {
          this.submitDelete()
        }
      })
    },
    submitDelete () {
      miscApi.deleteLogs({ logs: this.logs2Delete }).then((m) => {
        if (m.message === 'ok') {
          this.logs2Delete = []
          this.refresh()
        }
      })
    },
  },
  computed: {
    isLoading () {
      return this.loading
    },
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
  },
  watch: {
    windowHeight (height) {
      this.wHeight = height
    },
  },
}
</script>

<style scoped></style>
