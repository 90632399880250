var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{key:_vm.device.serialnumber,staticStyle:{"cursor":"pointer"},on:{"click":_vm.clicked}},[_c('td',[(_vm.device.annahmestelle && this.device.missed_refreshes === 0)?_c('v-icon',{staticClass:"fa fa-circle",attrs:{"color":"green"}}):_vm._e(),(
        _vm.device.annahmestelle &&
        this.device.missed_refreshes > 0 &&
        this.device.missed_refreshes < 3
      )?_c('v-icon',{staticClass:"fa fa-circle",attrs:{"color":"yellow"}}):_vm._e(),(
        _vm.device.annahmestelle &&
        this.device.missed_refreshes > 0 &&
        this.device.missed_refreshes >= 3
      )?_c('v-icon',{staticClass:"fa fa-circle",attrs:{"color":"red"}}):_vm._e()],1),_c('td',[(_vm.showTrash)?_c('v-btn',{attrs:{"icon":"","ripple":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteClicked.apply(null, arguments)}}},[_c('v-icon',{staticClass:"primary--text"},[_vm._v("fa-trash")])],1):_vm._e()],1),_c('td',[_c('v-avatar',{attrs:{"color":"primary","size":"40"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-tablet-alt")])],1)],1),_c('td',[_vm._v(_vm._s(_vm.device.serialnumber))]),_c('td',[_vm._v(" "+_vm._s(_vm.device.annahmestelle ? _vm.device.annahmestelle.annahmestellennr : '')+" ")]),_c('td',[_vm._v(_vm._s(_vm.shortAstinfo))]),_c('td',[_vm._v(_vm._s(_vm.device.ip))]),_c('td',[_vm._v(_vm._s(_vm.device.group ? _vm.device.group.name : ''))]),_c('td',[(_vm.device.manual_opening_times)?_c('i',{staticClass:"fa fa-check"}):_vm._e()]),_c('td',[_vm._v(_vm._s(_vm.formattedLastAccess))]),_c('td',[(_vm.device.report_count > 0)?_c('v-btn',{attrs:{"color":"primary","ripple":""},on:{"click":function($event){$event.stopPropagation();return _vm.scrollClicked.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.device.report_count)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("fa-bolt")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }