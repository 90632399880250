<template>
  <v-list-item @click="clicked">
    <v-list-item-action v-if="!item.is_default && showTrash">
      <v-btn icon ripple @click.stop="$emit('delete', item)">
        <v-icon class="primary--text">fa-trash</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-avatar>
      <v-icon class="primary white--text">fa-layer-group</v-icon>
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title
        :class="item.is_default ? 'font-weight-bold primary--text' : 'pl-4'"
      >
        {{ item.name }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: 'group-list-tile',
  props: {
    item: {},
    showTrash: Boolean,
  },
  computed: {},
  methods: {
    clicked () {
      this.$emit('click', this.item)
    },
  },
}
</script>
