import base from './base'

export default {
  deleteReports (settings) {
    return base.request('delete_reports', 'POST', settings)
  },

  getReport (id) {
    return base.request(`${id}/report`)
  },
}
