import base from './base'

export default {
  getAll () {
    return base.request('apps')
  },

  distributeAppToGroups (appVersionId, groupIds, date) {
    const data = {
      app_version_id: appVersionId,
      group_ids: groupIds,
      date,
    }
    return base.request(`apps/distribute`, 'POST', data)
  },

  getDistributionJobsForApp (appVersionId) {
    return base.request(`apps/distribute/${appVersionId}`, 'GET')
  },

  deleteDistributionJob (jobId) {
    return base.request(`apps/distribute/jobs/${jobId}`, 'DELETE')
  },

  updateApp (id, settings) {
    return base.request(`apps/${id}`, 'PUT', settings)
  },

  confirmUpload (id, settings) {
    return base.request(`apps/upload/${id}/confirm`, 'POST', settings)
  },

  deleteApp (id, settings) {
    return base.request(`apps/${id}`, 'DELETE', settings)
  },

  deleteVersion (id, settings) {
    return base.request(`apps/version/${id}`, 'DELETE', settings)
  },

  getAllExternal () {
    return base.request('external_apps')
  },

  getAllInstalled (id) {
    return base.request(`device/${id}/installed_apps`, 'GET')
  },

  createExternal (settings) {
    return base.request('external_apps', 'POST', settings)
  },

  updateExternal (id, settings) {
    return base.request(`external_apps/${id}`, 'PUT', settings)
  },

  deleteExternal (id, settings) {
    return base.request(`external_apps/${id}`, 'DELETE', settings)
  },

  getMissingGroupsForAppVersion (appVersionId) {
    return base.request(`apps/version/${appVersionId}/missing`, 'GET')
  },

  getGroupsWithAppVersion (appVersionId) {
    return base.request(`apps/version/${appVersionId}/groups`, 'GET')
  },
}
