<template>
  <v-form ref="dateform">
    <v-layout style="padding-left: 10%; padding-right: 10%">
      <v-flex>
        <v-card class="mt-4">
          <v-container>
            <v-flex class="py-4 primary--text">
              Bitte geben Sie das Umstellungsdatum für Eurojackpot ein. Die
              Änderungen sind in der STÄPP Testversion zu sehen.
            </v-flex>
            <v-text-field
              v-model="switchDate"
              :disabled="!authState.hasWriteAccess()"
              :hint="`Format: '${timeFormat}'`"
              :rules="[rules.required, rules.datetimeformat]"
              label="Eurojackpot Umstellungsdatum"
              outlined
            />
            <v-flex class="py-4 primary--text">
              Bitte geben Sie das Datum für die erste Ziehung ein
            </v-flex>
            <v-text-field
              v-model="firstDrawDate"
              :disabled="!authState.hasWriteAccess()"
              :hint="`Format: '${timeFormat}'`"
              :rules="[rules.required, rules.datetimeformat]"
              label="erstes Ziehungsdatum"
              outlined
            />
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-spacer/>
      <v-flex class="pt-2" style="padding-right: 10%" xs3>
        <v-btn
          v-if="authState.hasWriteAccess()"
          :class="[checkInputs ? 'secondary primary--text' : 'grey']"
          block
          class="font-weight-bold"
          large
          @click="confirmSaving"
        >
          Übernehmen
        </v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import moment from 'moment'
import AuthStore from '@/stores/AuthStore'
import switchDateAPI from '@/api/switchDate'
import ErrorStore from '@/stores/ErrorStore'

export default {
  name: 'EJSwitch',
  data () {
    return {
      authState: AuthStore.state,
      timeFormat: 'DD.MM.YYYY - HH:mm',
      switchDate: null,
      firstDrawDate: null,
      rules: {
        required: (v) => !!v || 'Dieses Feld ist verpflichtend',
        datetimeformat: (value) => {
          if (value) {
            const parsedTime = moment(value, this.timeFormat, true).isValid()
            if (!parsedTime) {
              return `Datum muss folgendem Format entsprechen: ${this.timeFormat}`
            }
            return true
          }
          return true
        },
      },
    }
  },
  mounted () {
    this.fetchEJSwitchDate()
    this.fetchEJFirstDrawDate()
  },
  methods: {
    fetchEJSwitchDate () {
      switchDateAPI
        .getEJSwitchDate()
        .then((r) => {
          if (r && Object.prototype.hasOwnProperty.call(r, 'switch_time')) {
            const switchMillis = r['switch_time']
            this.switchDate = moment(switchMillis).format(this.timeFormat)
          }
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
    fetchEJFirstDrawDate () {
      switchDateAPI
        .getEJFirstDrawDate()
        .then((r) => {
          if (r && Object.prototype.hasOwnProperty.call(r, 'switch_time')) {
            const switchMillis = r['switch_time']
            this.firstDrawDate = moment(switchMillis).format(this.timeFormat)
          }
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
    sendEJSwitchDate () {
      switchDateAPI
        .saveEJSwitchDate({
          switch_time: moment(this.switchDate, this.timeFormat, true).valueOf(),
        })
        .then(() => {
          ErrorStore.showErrorMessage(
            'Die Daten sind erfolgreich gespeichert',
            'Hinweis'
          )
          this.fetchEJSwitchDate()
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
    sendEJFirstDrawDate () {
      switchDateAPI
        .saveEJFirstDrawDate({
          switch_time: moment(
            this.firstDrawDate,
            this.timeFormat,
            true
          ).valueOf(),
        })
        .then(() => {
          ErrorStore.showErrorMessage(
            'Die Daten sind erfolgreich gespeichert',
            'Hinweis'
          )
          this.fetchEJSwitchDate()
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
    confirmSaving () {
      if (this.$refs.dateform.validate()) {
        this.sendEJSwitchDate()
        this.sendEJFirstDrawDate()
      } else {
        ErrorStore.showErrorMessage('Bitte prüfen Sie Ihre Angaben!')
      }
    },
  },
  computed: {
    checkInputs () {
      return (
        (this.switchDate
          ? moment(this.switchDate, this.timeFormat, true).isValid()
          : false) &&
        (this.firstDrawDate
          ? moment(this.firstDrawDate, this.timeFormat, true).isValid()
          : false)
      )
    },
  },
}
</script>

<style scoped></style>
