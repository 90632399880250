<template>
  <v-dialog
    :value="show"
    width="500"
    @click:outside="close"
    @keydown.esc="close">

    <v-card background-color="#F5F5F5">

      <v-toolbar color="secondary">
        <v-btn color="primary" icon @click="close">
          <v-icon>fa-window-close</v-icon>
        </v-btn>
        <v-toolbar-title class="primary--text">
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>

      <v-container class="px-16 pt-3 pb-3">

        <div
          v-if="editCase && (isValidUrl(image) || isValidUrl(description))"
          class="d-flex flex-column"
        >
          <v-card-text class="pa-0 font-weight-bold text--secondary">
            Aktuell hinterlegter Inhalt
          </v-card-text>
          <v-row align="center" no-gutters>
            <v-img
              :src="image"
              contain
              max-height="180"/>

            <AppHyperlink
              v-if="isValidUrl(description)"
              :label="extractFilename(description)"
              :url="description"/>
          </v-row>
        </div>

        <!-- IMAGE -->
        <v-card-text class="mt-0 pa-0 mb-2 pt-4 font-weight-bold text--secondary">
          Losbild
        </v-card-text>
        <vue-dropzone
          id="dropzone"
          ref="imageDropzone"
          :options="dropzoneOptionsImage"
          @vdropzone-success="imageUploadSuccess"
          @vdropzone-removed-file="imageUploadData = null"/>

        <!-- DESCRIPTION -->
        <v-card-text class="mt-0 pa-0 mb-2 pt-4 font-weight-bold text--secondary">
          Losinformationen (PDF)
        </v-card-text>
        <vue-dropzone
          id="dropzone"
          ref="descriptionDropzone"
          :options="dropzoneOptionsDescription"
          @vdropzone-success="descriptionUploadSuccess"
          @vdropzone-removed-file="descriptionUploadData = null"/>

        <!-- FORM -->
        <v-form ref="form" class="mt-2">
          <v-text-field
            v-model="name"
            :rules="rules.required"
            label="Name"/>

          <v-text-field
            v-model="publishAt"
            :hint="inputDateFormat"
            :rules="publishAt && publishAt.length > 0 ? rules.date : [true]"
            label="- Verfügbar ab (optional) -"/>

          <v-text-field
            v-model="publishUntil"
            :hint="inputDateFormat"
            :rules="publishUntil && publishUntil.length > 0 ? rules.date : [true]"
            label="- Verfügbar bis (optional) -"/>

          <ColorPickerMenu
            :color="tempBackgroundColor"
            label="Hintergrundfarbe"
            @cancel="tempBackgroundColor = null"
            @confirm="tempBackgroundColor = $event"/>

          <v-row justify="center" no-gutters>
            <v-btn class="primary--text" color="secondary" large @click="submit">
              Speichern
            </v-btn>
          </v-row>

        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorStore from '@/stores/ErrorStore'
import vueDropzone from 'vue2-dropzone'
import config from '@/config'
import LotApi from '@/api/lots'
import LotStore from '@/stores/LotStore'
import AuthStore from '@/stores/AuthStore'
import AppHyperlink from '../AppHyperlink.vue'
import moment from 'moment'
import timeUtils from '@/utils/time'
import ColorPickerMenu from './ColorPickerMenu.vue'

export default {
  name: 'lot-dialog',
  components: {
    ColorPickerMenu,
    AppHyperlink,
    vueDropzone
  },
  data () {
    return {
      url: `${config.adminUrl}/lots/upload`,
      timeFormat: 'DD.MM.YYYY HH:mm',
      inputDateFormat: 'Format: DD.MM.YYYY - HH:MM',

      imageUploadData: null, /* File | null */
      descriptionUploadData: null, /* File | null */
      name: '',
      publishAt: null,
      publishUntil: null,

      // This is the color currently in picker, may be unsaved (not submitted to server)
      tempBackgroundColor: null,

      //
      rules: {
        required: [
          v => !!v || 'Feld ist erforderlich.'
        ],
        date: [
          v => {
            if (v && v.length > 0) {
              return /^\d{2}\.\d{2}\.\d{4} - \d{2}:\d{2}$/.test(v) || `Bitte ein gültiges Datum im Format: ${this.inputDateFormat} angeben.`
            }
            // if no value given (or empty) it is ok for this rule
            return true
          },
          v => {
            if (v && v.length > 0) {
              const parsedTime = moment(v.replace(' - ', ' '), this.timeFormat, true).isValid()
              if (!parsedTime) {
                return `Bitte ein gültiges Datum im Format: ${this.inputDateFormat} angeben.`
              }
              return true
            }
            // if no value given (or empty) it is ok for this rule
            return true
          }
        ],
      }
    }
  },
  computed: {
    dropzoneOptionsImage () {
      return {
        url: this.url,
        headers: {
          'X-Auth-Token': AuthStore.state.apiToken,
        },
        addRemoveLinks: true,
        paramName: 'image',
        timeout: 100000,
        acceptedFiles: 'image/png, image/jpg, image/jpeg',
        maxFiles: 1,
      }
    },
    dropzoneOptionsDescription () {
      return {
        url: this.url,
        headers: {
          'X-Auth-Token': AuthStore.state.apiToken,
        },
        addRemoveLinks: true,
        paramName: 'description',
        timeout: 100000,
        acceptedFiles: 'application/pdf',
        maxFiles: 1,
      }
    },
    dialogState () /* Object | null */ {
      return LotStore.state.dialogState
    },
    show () {
      return this.dialogState ? this.dialogState.visible : false
    },
    editCase () /* boolean */ {
      return this.dialogState ? !this.dialogState.isNew : false
    },
    title () {
      return this.editCase ? 'Los bearbeiten' : 'Neues Los'
    },
    lot () {
      return this.dialogState ? this.dialogState.lot : null
    },
    lotName () {
      return this.lot ? this.lot.name : ''
    },
    computedPublishAt () {
      return this.lot ? timeUtils.formattedDate(this.lot.publish_at) : ''
    },
    computedPublishUntil () {
      return this.lot ? timeUtils.formattedDate(this.lot.publish_until) : ''
    },
    backgroundColor () {
      return this.lot ? this.lot.color : ''
    },
    image () /*: string|null */ {
      if (this.show) {
        if (this.lot && this.lot.files) {
          const imageItem = this.lot.files.find((fileItem) => fileItem.file_type === 'image')
          if (imageItem && imageItem.url) {
            return imageItem.url
          }
        }
      }
      return null
    },
    description () /*: string|null */ {
      if (this.show) {
        if (this.lot && this.lot.files) {
          const descriptionItem = this.lot.files.find((fileItem) => fileItem.file_type === 'description')
          if (descriptionItem && descriptionItem.url) {
            return descriptionItem.url
          }
        }
      }
      return null
    }
  },
  watch: {
    show (nowShowing) {
      if (nowShowing && this.editCase) {
        this.name = this.lotName
        this.publishAt = this.computedPublishAt
        this.publishUntil = this.computedPublishUntil
        this.tempBackgroundColor = this.backgroundColor
      } else if (!nowShowing) {
        this.close()
      }
    }
  },
  methods: {
    imageUploadSuccess (file, response) {
      this.imageUploadData = response
    },

    descriptionUploadSuccess (file, response) {
      this.descriptionUploadData = response
    },

    submit () {
      // First, validate the form, if ok only proceed if also computed errors do not exist
      if (this.$refs.form.validate()) {
        if (this.editCase && this.lot) {
          // If it's edit mode, directly proceed to update the lot
          this.updateLot()
        } else {
          if (!this.imageUploadData || !this.imageUploadData.image) {
            ErrorStore.showErrorMessage('Bitte ein Bild hochladen', 'Fehler')
            return
          }
          // Proceed to create the lot if an image was uploaded
          this.createLot()
        }
      }
    },

    createLot () {
      const data = {
        name: this.name,
        files: {
          image: this.imageUploadData.image
        },
        publish_at: this.publishAt && this.publishAt.length > 0 ? this.publishAt : null,
        publish_until: this.publishUntil && this.publishUntil.length > 0 ? this.publishUntil : null,
        color: this.tempBackgroundColor && this.tempBackgroundColor.length > 0 ? this.tempBackgroundColor : null
      }
      if (this.descriptionUploadData && this.descriptionUploadData.description) {
        data.files.description = this.descriptionUploadData.description
      }
      LotApi.create(data).then((r) => {
        ErrorStore.showErrorMessage(
          'Los erfolgreich erstellt',
          'Erfolg'
        )
        this.close()
      }).catch((err) => {
        ErrorStore.showError(err)
      })
    },

    updateLot () {
      const data = {
        name: this.name,
        publish_at: this.publishAt && this.publishAt.length > 0 ? this.publishAt : null,
        publish_until: this.publishUntil && this.publishUntil.length > 0 ? this.publishUntil : null,
        color: this.tempBackgroundColor && this.tempBackgroundColor.length > 0 ? this.tempBackgroundColor : null
      }
      // check if image was uploaded and append info to data
      if ((this.imageUploadData && this.imageUploadData.image) || (this.descriptionUploadData && this.descriptionUploadData.description)) {
        data.files = {
          image: this.imageUploadData && this.imageUploadData.image ? this.imageUploadData.image : undefined,
          description: this.descriptionUploadData && this.descriptionUploadData.description ? this.descriptionUploadData.description : undefined
        }
      }
      LotApi.update(this.lot.id, data).then((r) => {
        ErrorStore.showErrorMessage(
          'Los erfolgreich editiert',
          'Erfolg'
        )
        this.close()
      }).catch((err) => {
        ErrorStore.showError(err)
      })
    },

    close () {
      // local dismissal (this will be triggered from store functions or user X click)
      this.imageUploadData = null
      this.descriptionUploadData = null
      this.name = ''
      this.publishAt = ''
      this.publishUntil = ''
      this.tempBackgroundColor = ''
      // This works to clear the dropzone, the IDE can't resolve the function somehow
      if (this.$refs.imageDropzone) {
        this.$refs.imageDropzone.removeAllFiles()
      }
      if (this.$refs.descriptionDropzone) {
        this.$refs.descriptionDropzone.removeAllFiles()
      }
      // Reset the validation for the form
      this.$refs.form.reset()
      this.$refs.form.resetValidation()
      // communicate closing (emit for reloads)
      LotStore.commit('dismissLotDialog')
      this.$emit('close')
    },

    isValidUrl (url) {
      if (url) {
        const staticPart = '/lots/files/'
        const regex = new RegExp(`${staticPart}[^/]+(?:/[^/]+)*\\.[a-zA-Z]{3,4}$`)
        // console.log(`evaluation result for ${url}`, regex.test(url))
        return regex.test(url)
      }
      return false
    },

    extractFilename (url) {
      const parsedUrl = new URL(url)
      const pathname = parsedUrl.pathname
      const parts = pathname.split('/')
      // Combine the last two parts, excluding the filename itself
      // noinspection UnnecessaryLocalVariableJS
      const filePath = parts.slice(-1).join('/')
      return filePath
    }
  }
}
</script>

<style lang="scss">
.dropzone {
  border: gray dashed 1px;
}
</style>
