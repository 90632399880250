<template>
  <v-dialog :value="value" max-width="800" @click:outside="cancel">
    <v-card>
      <v-toolbar color="secondary">
        <v-btn color="primary" icon @click="cancel">
          <v-icon>fa-window-close</v-icon>
        </v-btn>
        <v-toolbar-title class="primary--text">Upload App</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            v-if="uploadResponse"
            class="primary--text body-2"
            text
            @click="save()"
          >Bestätigen
          </v-btn
          >
        </v-toolbar-items>
      </v-toolbar>

      <v-container>
        <file-upload-box
          v-if="uploadResponse === null"
          :file-types="['.apk']"
          :url="url"
          param-name="app"
          @uploaded="uploaded"
        />
        <temp-app-form
          v-if="uploadResponse"
          ref="tempform"
          :response="uploadResponse"
        ></temp-app-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import FileUploadBox from '../components/Apps/FileUploadBox'
import TempAppForm from '../components/Apps/TempAppForm'
import appApi from '@/api/apps'
import errorStore from '@/stores/ErrorStore'
import config from '@/config'

export default {
  name: 'upload-dialog',
  data () {
    return {
      uploadResponse: null,
      url: `${config.adminUrl}/apps/upload`,
    }
  },
  components: {
    TempAppForm,
    FileUploadBox,
  },
  props: ['value'],
  methods: {
    uploaded (response) {
      this.uploadResponse = response
    },
    cancel () {
      this.uploadResponse = null
      this.$emit('input', false)
    },
    save () {
      if (this.$refs.tempform.validate()) {
        appApi
          .confirmUpload(this.uploadResponse.temp_id, {
            name: this.uploadResponse.name,
          })
          .then(() => {
            this.uploadResponse = null
            this.$emit('input', false)
            this.$emit('reload')
          })
          .catch((err) => {
            errorStore.showError(err)
          })
      }
    },
  },
}
</script>
