<template>
  <div>
    <v-dialog v-model="showDetails" width="800">
      <v-card v-if="reportDetails">
        <v-card-title class="headline secondary primary--text" primary-title>
          {{ reportedAt }} - {{ reportDetails.device.serialnumber }}
        </v-card-title>
        <v-card-text>{{ reportDetails.content }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="secondary primary--text"
            text
            @click="showDetails = false"
          >Ok
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-sheet class="pa-3 primary lighten-2">
      <v-select
        v-model="selectedType"
        :items="reportTypes"
        dark
        item-text="name"
        item-value="value"
      ></v-select>
    </v-sheet>
    <v-container fluid>
      <v-layout class="pa-3" justify-end row wrap>
        <v-flex shrink>
          <v-btn
            v-if="reportsToDelete.length > 0"
            color="primary"
            @click="confirmDelete"
          >({{ reportsToDelete.length }}) ausgewählte Löschen
          </v-btn>
        </v-flex>
      </v-layout>
      <v-data-table
        :footer-props="footerProps"
        :headers="tableHeaders"
        :height="wHeight * 0.7"
        :items="displayReports"
        :loading="isLoading ? 'loading' : false"
        :options.sync="tableOptions"
        class="elevation-1"
        fixed-header
        loading-text="Daten werden geladen ..."
        @pagination="paginationChanged"
      >
        <template v-slot:item="props">
          <report-list-row
            :allow-interaction="authState.hasWriteAccess()"
            :checkedList="reportsToDelete"
            :report="props.item"
            @click="reportClicked"
            @updateList="updateList"
          ></report-list-row>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import annahmestellenApi from '@/api/device'
import ReportListRow from '@/components/Devices/ReportListRow'
import timeUtils from '@/utils/time'
import ReportsApi from '@/api/reports'
import QuestionStore from '@/stores/QuestionStore'
import AuthStore from '@/stores/AuthStore'

export default {
  name: 'AllReports',
  components: { ReportListRow },
  data () {
    return {
      reportTypes: [
        {
          value: null,
          name: 'Alle Apps'
        },
        {
          value: 'staepp-cloud',
          name: 'STÄPP Cloud'
        },
        {
          value: 'staepp',
          name: 'STÄPP'
        },
        {
          value: 'oddset',
          name: 'Oddset'
        },
      ],
      selectedType: null,
      reports: [],
      reportDetails: null,
      showDetails: false,
      reportsToDelete: [],
      authState: AuthStore.state,
      footerProps: {
        'items-per-page-options': [20, 50, 100, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
        sortBy: ['reported_at'],
        sortDesc: [true],
      },
      loading: true,
      wHeight: 0,
    }
  },
  mounted () {
    this.refresh()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  methods: {
    refresh () {
      annahmestellenApi.getAllReports().then((r) => {
        this.reports = r
        this.loading = false
      })
    },
    confirmDelete () {
      QuestionStore.poseQuestion(
        'Ausgewählte Absturzberichte unwiderruflich entfernen?',
        ['Löschen', 'Abbrechen']
      ).then((answer) => {
        if (answer === 0) {
          this.submitDelete()
        }
      })
    },
    reportClicked (report) {
      ReportsApi.getReport(report.report_id).then((r) => {
        this.reportDetails = r
        this.showDetails = true
      })
    },
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
    updateList (checked, report) {
      if (checked) {
        this.reportsToDelete.push(report)
      } else {
        var removeIndex = this.reportsToDelete.indexOf(report)
        this.reportsToDelete.splice(removeIndex, 1)
      }
    },

    submitDelete () {
      ReportsApi.deleteReports({ reports: this.reportsToDelete }).then((a) => {
        if (a.message === 'ok') {
          this.reportsToDelete = []
          this.refresh()
        }
      })
    },
  },
  computed: {
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    isLoading () {
      return this.loading
    },
    reportedAt () {
      return this.reportDetails
        ? timeUtils.formattedDate(this.reportDetails.reported_at)
        : ''
    },
    displayReports () {
      if (this.selectedType === null) {
        return this.reports
      }
      const validTypes = {
        staepp: ['staepp'],
        'staepp-cloud': ['staepp-cloud', 'crash'],
        oddset: ['oddset'],
      }
      const types = validTypes[this.selectedType]
      return this.reports.filter((r) => {
        return types.includes(r.type)
      })
    },
    tableHeaders () {
      return [
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: 'App',
          value: null,
          sortable: false
        },
        {
          text: 'Datum',
          value: 'reported_at'
        },
        {
          text: 'Gerät',
          value: 'device.serialnumber'
        },
        {
          text: 'Annahmestelle',
          value: 'device.annahmestelle.annahmestellennr',
        },
      ]
    },
  },
  watch: {
    windowHeight (height) {
      this.wHeight = height
    },
  },
}
</script>

<style scoped></style>
