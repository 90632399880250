<template>
  <v-container fluid>
    <doc-upload-dialog
      v-model="uploadDialogVisible"
      :categories="categories"
      @close="close"
      @reload="reload"
    >
    </doc-upload-dialog>
    <doc-edit-dialog
      v-model="editDialogVisible"
      :edited_doc="editedDoc"
      @close="closeEditingDialog"
      @reload="reload"
    >
    </doc-edit-dialog>
    <v-row class="mb-1">
      <v-col cols="4">
        <v-select
          v-model="selectedCategory"
          :items="categoriesWithAll"
          filled
          hide-details
          label="Kategorie"
        />
      </v-col>

      <v-col v-if="canUpload">
        <v-layout>
          <v-card-text class="primary--text text-right font-weight-bold"
          >Neues Dokument
          </v-card-text
          >
          <v-btn
            class="primary"
            fab
            style="width: 50px; height: 50px"
            @click="showUploadDialog"
          >
            <!-- Style needed for display bug on firefox -->
            <v-icon class="white--text" style="display: inline-flex"
            >fa-plus
            </v-icon>
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
    <v-data-table
      :footer-props="footerProps"
      :headers="tableHeaders"
      :height="wHeight * 0.7"
      :items="filteredDocs"
      :options.sync="tableOptions"
      class="elevation-1"
      fixed-header
      @pagination="paginationChanged"
    >
      <template v-slot:item="row">
        <doc-list-row
          :allow-interaction="canUpload"
          :doc="row.item"
          @click="showUploadDialog"
          @delete="deleteDocument(row.item)"
          @editDoc="showEditDialog"
        />
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import DocApi from '@/api/docs'
import DocListRow from '@/components/Content/DocListRow'
import DocUploadDialog from '@/components/Content/DocUploadDialog'
import DocEditDialog from '@/components/Content/DocEditDialog'
import AuthStore from '@/stores/AuthStore'
import ErrorStore from '@/stores/ErrorStore'
import QuestionStore from '@/stores/QuestionStore'
import timeUtils from '@/utils/time'

export default {
  name: 'all-docs',
  components: {
    DocListRow,
    DocUploadDialog,
    DocEditDialog
  },
  data () {
    return {
      docs: [],
      search: '',
      uploadDialogVisible: false,
      uploading: false,
      docDetails: null,
      editDialogVisible: false,
      editedDoc: null,
      canUpload: AuthStore.state.hasDocUploadAccess(),
      categories: [
        'Broschüren',
        'Datenschutzerklärung',
        'Jugendschutz',
        'Spielerschutz',
        'Teilnahmebedingungen',
        'Impressum',
        'Über LOTTO Bayern',
        'Weitere Teilnahmebedingungen',
      ],
      allCategories: '- Alle Kategorien -',
      selectedCategory: '- Alle Kategorien -',
      footerProps: {
        'items-per-page-options': [20, 50, 100, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
        sortBy: ['key'],
      },
      wHeight: 0,
    }
  },
  methods: {
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
    showUploadDialog () {
      if (this.canUpload) {
        this.uploadDialogVisible = true
      }
    },
    uploadDoc () {
      this.uploading = true
    },
    reload () {
      DocApi.getAll().then((d) => {
        this.docs = d
      })
    },
    close () {
      this.uploadDialogVisible = false
      this.docDetails = null
    },
    closeEditingDialog () {
      this.editDialogVisible = false
      this.editedDoc = null
    },
    deleteDocument (document) {
      QuestionStore.poseQuestion(
        `Dokument "${document.key}" - "${document.title}" wirklich löschen?`,
        ['Nein', 'Ja'],
        0
      ).then((idx) => {
        if (idx === 1) {
          DocApi.delete(document)
            .then((_) => {
              ErrorStore.showErrorMessage('Dokument gelöscht', 'Erfolg')
              this.reload()
            })
            .catch((err) => {
              ErrorStore.showError(err)
            })
        }
      })
    },
    showEditDialog (doc) {
      // Create deep clone of the doc object
      this.editedDoc = JSON.parse(JSON.stringify(doc))
      // Change and localize time format before sending the cloned object to the edit dialog
      this.editedDoc.publish_at = timeUtils.formattedDate(doc.publish_at)
      this.editedDoc.publish_until = timeUtils.formattedDate(doc.publish_until)
      this.editDialogVisible = true
    },
  },
  mounted () {
    this.reload()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  computed: {
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    categoriesWithAll () {
      return [this.allCategories].concat(this.categories)
    },
    tableHeaders () {
      return [
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: 'Kategorie',
          value: 'category'
        },
        {
          text: 'Key',
          value: 'key'
        },
        {
          text: 'Titel',
          value: 'title'
        },
        {
          text: 'Verfügbar ab',
          values: 'publish_at'
        },
        {
          text: 'Verfügbar bis',
          values: 'publish_until'
        },
        {
          text: 'Letzte Aktualisierung',
          value: 'last_modified_global'
        },
        { text: '' },
        { text: '' },
      ]
    },
    filteredDocs () {
      if (this.selectedCategory === this.allCategories) {
        return this.docs
      }
      return this.docs.filter((doc) => {
        return doc.category === this.selectedCategory
      })
    },
  },
  watch: {
    windowHeight (height) {
      this.wHeight = height
    },
  },
}
</script>

<style>
.theme--light.v-text-field--box > .v-input__control > .v-input__slot {
  background: white;
}
</style>
