export default {
  1001: 'Benutzer nicht gefunden',
  1003: 'Ungültiges Passwort',
  1004: 'Sie haben das Passwort zu oft falsch eingegeben. Lassen Sie Ihren Zugang von einem Admin wieder freigeben, um weitere Anmeldeversuche starten zu können.',
  2002: 'E-Mail benötigt',
  2003: 'Name benötigt',
  2004: 'Passwort benötigt',
  2005: 'E-Mail Adresse bereits vergeben',
  3005: 'Seriennummer benötigt',
  3006: 'Diese Seriennummer ist bereits eingetragen',
  3008: 'Öffnungszeiten haben ungültiges Format',
  3009: 'Urlaub hat ungültiges Format',
  5002: 'Version ist noch Gruppe oder Gerät zugeordnet und kann so lange nicht gelöscht werden',
  10203: '2FA Code ist nicht gültig',
}
