<template>
  <tr :key="device.serialnumber" style="cursor: pointer" @click="clicked">
    <td>
      <v-list-item-avatar>
        <v-icon class="primary white--text">fa-tablet-alt</v-icon>
      </v-list-item-avatar>
    </td>
    <td>
      <v-list-item-content>
        <v-list-item-title>{{ device.serialnumber }}</v-list-item-title>
      </v-list-item-content>
    </td>
    <td>
      <v-list-item-content>
        <v-list-item-subtitle v-if="device.annahmestelle" class="text--primary">
          {{ device.annahmestelle.annahmestellennr }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </td>
    <td>
      <v-list-item-subtitle v-if="device.ip">
        {{ device.ip }}
      </v-list-item-subtitle>
    </td>
  </tr>
</template>
<script>
export default {
  name: 'device-list-tile',
  props: ['device', 'showTrash'],
  mounted () {
  },
  computed: {
    serialNumberString () {
      if (this.device) {
        const serialnumbers = this.device.map((d) => {
          return d.serialnumber
        })
        return serialnumbers.join(', ')
      }
      return ''
    },
  },
  methods: {
    clicked () {
      this.$emit('click', this.device)
    },
    scrollClicked () {
      this.$emit('action-click', this.device)
    },
  },
}
</script>
