<template>
  <form-dialog
    v-if="app"
    :can-save="userCanWrite"
    :title="title"
    :value="value"
    @input="$emit('input', $event)"
    @save="save()"
  >
    <DistributePopup
      v-model="showDistribute"
      :app-version="distributeAppVersion"
      @updateUI="reload"
    />

    <v-layout class="pt-4" style="padding-left: 10%; padding-right: 10%">
      <v-flex>
        <v-card>
          <v-card-text class="title font-weight-bold">Stammdaten</v-card-text>
          <v-container fluid grid-list-xl>
            <v-form @submit.prevent="save()">
              <v-layout>
                <v-flex sm6 xs12>
                  <v-text-field
                    v-model="name"
                    :disabled="!userCanWrite"
                    label="Name"
                    required
                  />
                </v-flex>
                <v-flex sm6 xs12>
                  <v-text-field v-model="packagename" disabled label="Paket"/>
                </v-flex>
              </v-layout>
              <v-btn style="display: none" type="submit"/>
            </v-form>
            <v-layout>
              <v-flex xs4>
                <v-subheader class="body-1 grey--text font-weight-bold"
                >Version
                </v-subheader
                >
              </v-flex>
              <v-flex class="mr-4" xs3>
                <v-subheader class="body-1 grey--text font-weight-bold"
                >Zugewiesene Gruppen
                </v-subheader
                >
              </v-flex>
              <v-flex class="ml-2" xs1>
                <v-subheader class="body-1 grey--text font-weight-bold"
                >Download
                </v-subheader
                >
              </v-flex>
            </v-layout>
            <v-divider/>

            <v-list two-line>
              <app-version-list-tile
                v-for="version in app.versions"
                :key="version.version_code"
                :heighestVersionId="getHighestVersionId"
                :item="version"
                :show-distribute="userCanWrite"
                :show-trash="userCanWrite"
                @ondistribute="distributeVersion(version)"
                @reload="reload"
              />
            </v-list>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </form-dialog>
</template>

<script>
import FormDialog from '../components/FormDialog'
import AppVersionListTile from '../components/Apps/AppVersionListTile'
import appApi from '@/api/apps'
import errorStore from '@/stores/ErrorStore'
import AuthStore from '@/stores/AuthStore'
import DistributePopup from '@/components/Apps/DistributePopup'

export default {
  name: 'app-details',
  components: {
    DistributePopup,
    AppVersionListTile,
    FormDialog
  },
  props: ['app', 'value'],
  data () {
    return {
      name: '',
      packagename: '',
      showDistribute: false,
      distributeAppVersion: null,
    }
  },
  computed: {
    title () {
      if (this.app) {
        return `${this.app.name} - ${this.app.packagename}`
      }
      return 'Neue App'
    },
    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },
    getHighestVersionId () {
      return this.app.versions[this.app.versions.length - 1].version_id
    },
  },
  watch: {
    app (a) {
      if (a) {
        this.name = a.name
        this.packagename = a.packagename
      } else {
        this.name = ''
        this.packagename = ''
      }
    },
  },
  methods: {
    distributeVersion (appVersion) {
      this.showDistribute = true
      this.distributeAppVersion = appVersion
    },
    save () {
      const settings = {
        name: this.name,
      }

      appApi
        .updateApp(this.app.app_id, settings)
        .then((u) => {
          this.$emit('reload')
          this.$emit('input', false)
        })
        .catch((err) => {
          errorStore.showError(err)
        })
    },
    reload () {
      this.$emit('reload')
    },
  },
}
</script>

<style scoped></style>
