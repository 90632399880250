<template>
  <div>
    <external-app-details
      v-model="detailsDialog"
      :app="appDetails"
      @reload="reload"
    ></external-app-details>
    <v-sheet class="pa-3 primary lighten-2">
      <v-text-field
        v-model="search"
        clear-icon="mdi-close-circle-outline"
        clearable
        dark
        flat
        hide-details
        label="Externe Apps durchsuchen"
        prepend-inner-icon="fa-search"
        solo-inverted
      ></v-text-field>
    </v-sheet>
    <v-container fluid>
      <v-card>
        <v-container>
          An dieser Stelle können Apps freigegeben werden, die entweder über
          andere Quellen installiert werden oder deren Vorinstallation auf dem
          Gerät angenommen werden kann
        </v-container>
      </v-card>

      <v-list subheader two-line>
        <v-subheader inset>Apps</v-subheader>

        <app-list-tile
          v-for="item in displayApps"
          :key="item.id"
          :item="item"
          default-icon="fa-mobile-alt"
          @click="showDetails"
          @delete="deleteExternal"
        />
      </v-list>
      <v-btn
        v-if="userCanWrite"
        bottom
        color="primary"
        dark
        fab
        fixed
        right
        style="margin-bottom: 50pt"
        @click="createApp"
      >
        <v-icon>fa-plus</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import appApi from '@/api/apps'
import AppListTile from '../components/Apps/AppListTile'
import ExternalAppDetails from './ExternalAppDetails'
import QuestionStore from '@/stores/QuestionStore'
import AuthStore from '@/stores/AuthStore'

export default {
  name: 'all-external-apps',
  components: {
    ExternalAppDetails,
    AppListTile
  },
  data () {
    return {
      apps: [],
      search: '',
      detailsDialog: false,
      appDetails: null,
    }
  },
  methods: {
    showDetails (app) {
      this.detailsDialog = true
      this.appDetails = app
    },
    createApp () {
      this.detailsDialog = true
      this.appDetails = null
    },
    deleteExternal (app) {
      QuestionStore.poseQuestion(
        'Externe App wirklich löschen? Dies kann nicht rückgängig gemacht werden',
        ['Ja', 'Nein']
      ).then((answer) => {
        if (answer === 0) {
          this.reallyDeleteExternal(app)
        }
      })
    },
    reallyDeleteExternal (app) {
      appApi.deleteExternal(app.external_app_id).then(() => {
        this.reload()
      })
    },
    reload () {
      appApi.getAllExternal().then((a) => {
        this.apps = a
      })
    },
  },

  mounted () {
    this.reload()
  },
  computed: {
    displayApps () {
      if (this.search === '') {
        return this.apps
      }
      return this.apps.filter((a) => {
        return (
          a.name.includes(this.search) || a.packagename.includes(this.search)
        )
      })
    },

    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },
  },
}
</script>
<style lang="scss" scoped>
.v-icon {
  height: auto;
}
</style>
