<template>
  <tr
    :key="doc.key"
    :class="textClass"
    style="cursor: pointer"
    @click="clicked"
  >
    <td>
      <i class="fas fa-file-pdf"/>
    </td>
    <td>{{ doc.category }}</td>
    <td>{{ doc.key }}</td>
    <td>{{ doc.title }}</td>
    <td>{{ formattedPublishedAt }}</td>
    <td>{{ formattedPublishedUntil }}</td>
    <td>{{ formattedLastModification }}</td>
    <td>
      <v-btn v-if="allowInteraction" icon ripple @click.stop="$emit('delete')">
        <v-icon class="primary--text">fa-trash</v-icon>
      </v-btn>
    </td>
    <td>
      <v-btn
        v-if="allowInteraction"
        icon
        ripple
        @click.stop="$emit('editDoc', doc)"
      >
        <v-icon class="primary--text">fa-edit</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script>
import timeUtils from '@/utils/time'

export default {
  name: 'doc-list-row',
  props: ['doc', 'allowInteraction'],
  data () {
    return {}
  },
  computed: {
    textClass () {
      return this.doc.is_active ? 'black--text' : 'grey--text'
    },
    formattedPublishedAt () {
      return timeUtils.formattedDate(this.doc.publish_at)
    },

    formattedPublishedUntil () {
      return timeUtils.formattedDate(this.doc.publish_until)
    },

    formattedLastModification () {
      return timeUtils.formattedDate(this.doc.last_modified_global)
    },
  },
  methods: {
    clicked () {
      window.open(this.doc.src)
    },
  },
}
</script>
<style>
i {
  font-size: 25px;
  color: red;
}
</style>
