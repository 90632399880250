<template>
  <tr :key="report.report_id" style="cursor: pointer">
    <td>
      <v-checkbox
        v-if="allowInteraction"
        :value="isChecked"
        hide-details
        primary
        style="margin: auto 0"
        v-on:change="updateReportsList"
      ></v-checkbox>
    </td>
    <td @click="$emit('click', report)">
      <v-avatar color="primary" size="40">
        <img v-if="image" :alt="report.type" :src="image"/>
        <v-icon v-if="icon" dark>{{ icon }}</v-icon>
      </v-avatar>
    </td>
    <td @click="$emit('click', report)">{{ formattedreportDate }}</td>
    <td @click="$emit('click', report)">
      {{ report.device ? report.device.serialnumber : '' }}
    </td>
    <td @click="$emit('click', report)">
      {{
        report.device
          ? report.device.annahmestelle
            ? report.device.annahmestelle.annahmestellennr
            : ''
          : ''
      }}
    </td>
  </tr>
</template>

<script>
import icOddset from '@/assets/oddset.png'
import icStaepp from '@/assets/staepp.png'
import icStaeppCloud from '@/assets/staepp-cloud.png'
import timeUtils from '@/utils/time'

export default {
  name: 'report-list-row',
  props: ['report', 'checkedList', 'allowInteraction'],
  data () {
    return {}
  },
  computed: {
    formattedreportDate () {
      return timeUtils.formattedDate(this.report.reported_at)
    },
    image () {
      if (this.report.type === 'crash' || this.report.type === 'staepp-cloud') {
        return icStaeppCloud
      } else if (this.report.type === 'staepp') {
        return icStaepp
      } else if (this.report.type === 'oddset') {
        return icOddset
      }
      return null
    },
    icon () {
      if (this.report.type === 'analytics') {
        return 'fa-chart-line'
      }
      return null
    },
    isChecked () {
      if (this.checkedList.length === 0) {
        return false
      }
      if (this.checkedList.indexOf(this.report) === -1) {
        return false
      }
      return true
    },
  },
  methods: {
    updateReportsList (value) {
      this.$emit('updateList', value, this.report)
    },
  },
}
</script>

<style scoped></style>
