import Vue from 'vue'
import Vuex from 'vuex'
import sessionStore from './AuthStore'
import lotStore from './LotStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    sessionStore,
    lotStore,
  }
})
