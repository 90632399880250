<template>
  <v-dialog
    :value="value"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    @input="$emit('input', $event)"
  >
    <v-card color="#F5F5F5">
      <v-toolbar color="secondary" fixed>
        <v-btn color="primary" icon @click="close">
          <v-icon>fa-window-close</v-icon>
        </v-btn>
        <v-toolbar-title class="primary--text">{{ title }}</v-toolbar-title>
        <v-spacer/>
        <v-toolbar-items>
          <v-btn
            v-if="canSave"
            class="primary--text body-2"
            text
            @click="$emit('save')"
          >Speichern
          </v-btn
          >
        </v-toolbar-items>
      </v-toolbar>

      <slot/>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    value: Boolean,
    canSave: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close () {
      this.$emit('input', false)
      for (let elem of document.getElementsByClassName('v-dialog')) {
        elem.scrollTo(0, 0)
      }
    },
  },
}
</script>
