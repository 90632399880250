import base from './base'

export default {
  getAll () {
    return base.request('users')
  },

  updateUser (id, settings) {
    return base.request(`users/${id}`, 'PUT', settings)
  },

  createUser (settings) {
    return base.request('users', 'POST', settings)
  },

  getRoles () {
    return base.request('roles')
  },

  delete (user) {
    return base.request(`users/${user.user_id}`, 'DELETE')
  },
}
