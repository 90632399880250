<template>
  <v-list-item @click="clicked">
    <v-list-item-action v-if="showTrash">
      <v-btn icon ripple @click.stop="$emit('delete', item)">
        <v-icon class="primary--text">fa-trash</v-icon>
      </v-btn>
    </v-list-item-action>
    <v-list-item-avatar>
      <v-icon v-if="!item.icon" class="primary white--text">{{
          defaultIcon
        }}
      </v-icon>
      <img v-if="item.icon" :src="item.icon"/>
    </v-list-item-avatar>

    <v-list-item-content class="pl-4">
      <v-list-item-title>{{ item.name }}</v-list-item-title>
      <v-list-item-subtitle>{{ item.packagename }}</v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
export default {
  name: 'app-list-tile',
  props: {
    item: {},
    'default-icon': {
      type: String,
      default: 'fa-cloud',
    },
    showTrash: Boolean,
  },
  computed: {},
  methods: {
    clicked () {
      this.$emit('click', this.item)
    },
  },
}
</script>
