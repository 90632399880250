<template>
  <v-app id="inspire">
    <question-dialog></question-dialog>
    <error-popup></error-popup>

    <v-navigation-drawer
      v-model="drawer"
      :class="`${menuBackground} ${menuText}`"
      :floating="false"
      app
      clipped
      overflow
    >
      <v-list dense nav>
        <drawer-item
          v-for="item in items"
          :key="item.text"
          :icon="item.icon"
          :link="item.link"
          :title="item.text"
        />
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      :class="`${menuBackground} ${menuText}`"
      app
      clipped-left
      dense
      fixed
    >
      <v-btn icon @click.stop="drawer = !drawer">
        <v-icon :class="`${menuBackground} ${menuText}`">$menu</v-icon>
      </v-btn>

      <v-toolbar-title class="mr-5 align-center">
        <span class="title">{{ title }}</span>
      </v-toolbar-title>
      <v-toolbar-title class="mr-10 align-right">
        <span class="title">{{ userName }}</span>
      </v-toolbar-title>
      <v-spacer/>
      {{ `v.${appCurrentVersion}` }}
    </v-app-bar>

    <v-content>
      <router-view></router-view>
    </v-content>
  </v-app>
</template>
<script>
import ErrorPopup from '@/components/ErrorPopup'
import DrawerItem from '@/components/DrawerItem'
import QuestionDialog from './components/QuestionDialog'
import AuthStore from '@/stores/AuthStore'
import config from '@/config'
import themeUtil from '@/utils/themeUtil'

export default {
  components: {
    QuestionDialog,
    DrawerItem,
    ErrorPopup,
  },
  name: 'App',
  data: () => ({
    state: AuthStore.state,
    drawer: null,
    appCurrentVersion: config.version,
  }),
  props: {
    source: String,
  },
  computed: {
    items () {
      if (!this.state.user) {
        return []
      }

      if (this.state.isEditor()) {
        return [
          {
            icon: 'fa-file-pdf',
            text: 'Dokumente',
            link: '/docs'
          },
          {
            icon: 'fa-ticket-alt',
            text: 'Lose',
            link: '/lots'
          },
          {
            icon: 'fa-users',
            text: 'Nutzer',
            link: '/users'
          },
          {
            icon: 'fa-tools',
            text: 'Wartungsfenster',
            link: '/maintenance'
          },
          {
            icon: 'fa-power-off',
            text: 'Ausloggen',
            link: '/logout'
          },
        ]
      }

      const items = [
        {
          icon: 'fa-tablet-alt',
          text: 'Geräte',
          link: '/device'
        },
        {
          icon: 'fa-layer-group',
          text: 'Gruppen',
          link: '/groups'
        },
        {
          icon: 'fa-cloud',
          text: 'Apps',
          link: '/apps'
        },
        {
          icon: 'fa-cubes',
          text: 'Externe Apps',
          link: '/external_apps'
        },
        {
          icon: 'fa-bolt',
          text: 'Absturzberichte',
          link: '/reports'
        },
        {
          icon: 'fa-book',
          text: 'Fehlerlogs',
          link: '/logs'
        },
        {
          icon: 'fa-users',
          text: 'Nutzer',
          link: '/users'
        },
        {
          icon: 'fab fa-btc',
          text: 'Bayernmillionen',
          link: '/bayernmillionen',
        },
        {
          icon: 'fa-file-pdf',
          text: 'Dokumente',
          link: '/docs'
        },
        {
          icon: 'fa-ticket-alt',
          text: 'Lose',
          link: '/lots'
        },
        {
          icon: 'fa-diagnoses',
          text: 'Analyse',
          link: '/analytics'
        },
        {
          icon: 'fa-tools',
          text: 'Wartungsfenster',
          link: '/maintenance'
        },
        {
          icon: 'fa-power-off',
          text: 'Ausloggen',
          link: '/logout'
        },
      ]
      if (this.state.isMobiPrivate()) {
        items.splice(items.length - 1, 0, {
          icon: 'fa-table',
          text: 'Logs',
          link: '/device_logs',
        })
      }
      if (themeUtil.isDevOrTestTheme() && AuthStore.state.isAdmin) {
        items.splice(items.length - 1, 0, {
          icon: 'fa-toggle-on',
          text: 'EJ-Umstellung',
          link: '/ej-switch',
        })
      }
      return items
    },

    userName () {
      return this.state.user ? this.state.user.email : ''
    },
    menuBackground () {
      if (config.theme === 'dev') {
        return 'purple darken-3'
      }
      if (config.theme === 'test') {
        return 'primary'
      }
      return 'secondary'
    },

    menuText () {
      if (config.theme === 'test' || config.theme === 'dev') {
        return 'secondary--text'
      }
      return 'primary--text'
    },
    title () {
      if (config.theme === 'dev') {
        return 'STÄPP Cloud Dev'
      }
      if (config.theme === 'test') {
        return 'STÄPP Cloud Test'
      }
      return 'STÄPP Cloud'
    },
  },
}
</script>
