<template>
  <draggable
    :list="lots"
    tag="tbody"
    @end="onDropCallback"
  >
    <tr
      v-for="(lot, index) in lots"
      :key="index"
      :class="textClass"
      style="cursor: pointer"
    >
      <td>
        <v-icon
          color="primary"
          medium
        >
          mdi-drag-horizontal-variant
        </v-icon>
        {{ lot.order }}
      </td>
      <td>
        <v-img
          v-if="image(lot)"
          :src="image(lot)"
          contain
          max-height="48.5"
          max-width="140"
          style="cursor: pointer"
          @click="imageClicked(lot)"/>
      </td>
      <td class="text-center">
        <div class="me-4">
          <i v-if="description(lot)" class="fa fa-check"/>
          <i v-else class="fa fa-times"/>
        </div>
      </td>
      <td>{{ lot.name }}</td>
      <td>{{ formattedPublishedAt(lot) }}</td>
      <td>{{ formattedPublishedUntil(lot) }}</td>
      <td class="text-center">
        <div class="me-4">
          <div
            v-if="boxBackgroundColor(lot)"
            :class="boxBackgroundColor(lot) && boxBackgroundColor(lot).toString().length > 0 ? 'color-box' : undefined"
            :style="boxBackgroundColor(lot)"/>
          <i v-else class="fa fa-times"/>
        </div>
      </td>
      <td>{{ formattedLastModification(lot) }}</td>
      <td>
        <v-btn v-if="allowInteraction" icon ripple @click.stop="$emit('delete', lot)">
          <v-icon class="primary--text">fa-trash</v-icon>
        </v-btn>
      </td>
      <td>
        <v-btn
          v-if="allowInteraction"
          icon
          ripple
          @click.stop="$emit('edit', lot)"
        >
          <v-icon class="primary--text">fa-edit</v-icon>
        </v-btn>
      </td>
    </tr>
  </draggable>
</template>

<script>
import Draggable from 'vuedraggable'
import timeUtil from '@/utils/time'

export default {
  name: 'lot-list-row',
  components: { Draggable },
  props: ['lots', 'allowInteraction'],
  data () {
    return {}
  },
  methods: {
    imageClicked (lot) {
      if (lot.src) {
        window.open(lot.src)
      }
    },
    textClass (lot) {
      return timeUtil.objectIsActive(lot.publish_at, lot.publish_until) ? 'black--text' : 'grey--text'
    },
    image (lot) {
      if (lot.files) {
        const imageItem = lot.files.find((fileItem) => fileItem.file_type === 'image')
        if (imageItem && imageItem.url) {
          return imageItem.url
        }
      }
      return null
    },
    description (lot) /*: string|null */ {
      if (lot && lot.files) {
        const descriptionItem = lot.files.find((fileItem) => fileItem.file_type === 'description')
        if (descriptionItem && descriptionItem.url) {
          return descriptionItem.url
        }
      }
      return null
    },
    formattedPublishedAt (lot) {
      return timeUtil.formattedDate(lot.publish_at)
    },
    formattedPublishedUntil (lot) {
      return timeUtil.formattedDate(lot.publish_until)
    },
    formattedLastModification (lot) {
      return timeUtil.formattedDate(lot.modified_at)
    },
    boxBackgroundColor (lot) {
      return lot.color
        ? {
          backgroundColor: lot.color
        }
        : undefined
    },
    onDropCallback (event/* : CustomEvent */) {
      this.$emit('drop', this.lots.map((lot) => lot.id))
    }
  }
}
</script>

<style>
i {
  font-size: 25px;
  color: red;
}

.color-box {
  width: 50px;
  height: 20px;
  display: inline-block;
  border: 1px solid #000;
}
</style>
