<template>
  <v-dialog :value="value" width="800" @click:outside="close">
    <v-card>
      <v-toolbar color="secondary">
        <v-btn color="primary" icon @click="close">
          <v-icon>fa-window-close</v-icon>
        </v-btn>
        <v-toolbar-title class="primary--text">
          Version <em>{{ versionCombination }}</em
        >&nbsp; verteilen
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <strong>Gruppen die eine andere App version nutzen:</strong>

        <v-data-table
          v-model="selectedGroups"
          :headers="headers"
          :items="groups"
          :options="{ itemsPerPage: -1 }"
          class="elevation-1"
          item-key="name"
          show-select
          style="height: 400px; overflow-y: scroll"
        >
          <template v-slot:items="group">
            <td style="width: 30px">
              <v-checkbox
                v-model="group.selected"
                hide-details
                primary
              ></v-checkbox>
            </td>
            <td>
              <strong>{{ group.item.name }}</strong>
            </td>
            <td>
              {{
                group.item.current_version
                  ? group.item.current_version.version_name
                  : '--'
              }}
            </td>
          </template>
        </v-data-table>
        <v-form>
          <v-layout>
            <v-flex xs2>
              <v-checkbox v-model="immediately" label="Sofort"/>
            </v-flex>
            <v-flex xs10>
              <v-text-field
                v-if="!immediately"
                v-model="date"
                hint="Format: 'dd.mm.yyyy - HH:MM"
                label="Verteildatum"
              />
            </v-flex>
          </v-layout>
        </v-form>

        <p v-if="warning" class="red--text">
          {{ warning }}
        </p>

        <div v-if="distributionJobs.length > 0">
          <h4>Geplant</h4>
          <v-data-table
            :items="distributionJobs"
            :options="{ itemsPerPage: -1 }"
            class="elevation-1"
            hide-default-footer
            hide-default-header
            item-key="job_id"
            style="height: 150px; overflow-y: scroll"
          >
            <template v-slot:item="job">
              <td>{{ localTime(job.item.distribution_date) }}</td>
              <td>{{ targetList(job.item.targets) }}</td>
              <td>
                <v-btn icon ripple @click.stop="deleteJob(job.item)">
                  <v-icon class="primary--text">fa-trash</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-card-text>
      <v-divider/>

      <v-card-actions>
        <v-spacer/>
        <v-btn
          class="font-weight-light primary--text"
          color="gray"
          large
          @click="close()"
        >
          Abbrechen
        </v-btn>
        <v-btn
          :disabled="selectedGroups.length === 0"
          class="font-weight-bold primary--text"
          color="secondary"
          large
          @click="distribute()"
        >
          Verteilen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import appsApi from '@/api/apps'
import ErrorStore from '@/stores/ErrorStore'
import moment from 'moment'

export default {
  name: 'DistributePopup',
  data () {
    return {
      immediately: true,
      date: null,
      groups: [],
      selectedGroups: [],
      warning: null,
      showWarningHint: false,
      distributionJobs: [],
      headers: [
        {
          text: 'Gruppe',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Aktuelle Version',
          sortable: true,
          value: 'current_version.version_name',
        },
      ],
    }
  },
  props: ['value', 'appVersion'],
  methods: {
    close () {
      this.$emit('input')
    },
    distribute () {
      if (!this.appVersion) {
        return
      }
      if (
        this.groups.length === this.selectedGroups.length &&
        !this.showWarningHint
      ) {
        this.warning =
          'Sie haben alle Gruppen ausgewählt. In einem Fehlerfall sind von diesem Update alle Geräte betroffen. Bitte drücken Sie ein weiteres Mal auf "Verteilen" um fortzusetzen'
        this.showWarningHint = true
        return
      }

      const date = this.immediately ? null : this.date

      if (!this.immediately && !this.warning && !this.showWarningHint) {
        // Verify date is during office hours
        const m = moment(this.date, 'DD.MM.YYYY - HH:mm')
        if (m.hour() < 8 || m.hour() >= 17) {
          this.warning =
            'Zeitpunkt liegt außerhalb der Servicezeiten. Im Fehlerfall steht kein Support zur Verfügung. Bitte drücken Sie ein weiteres Mal auf "Verteilen" um fortzusetzen'
          this.showWarningHint = true
          return
        }
      }

      const groupIds = this.selectedGroups.map((g) => {
        return g.group_id
      })
      appsApi
        .distributeAppToGroups(this.appVersion.version_id, groupIds, date)
        .then(() => {
          return this.updateApps()
        })
        .then(() => {
          if (this.immediately) {
            ErrorStore.showErrorMessage(
              'Version erfolgreich verteilt',
              'Hinweis'
            )
            this.$emit('updateUI')
          } else {
            ErrorStore.showErrorMessage(
              'Verteilung erfolgreich terminiert',
              'Hinweis'
            )
          }
        })
        .catch((error) => {
          ErrorStore.showError(error)
        })
    },
    updateApps () {
      if (!this.appVersion) {
        return
      }
      return appsApi
        .getMissingGroupsForAppVersion(this.appVersion.version_id)
        .then((g) => {
          this.groups = g
          this.warning = null
          return appsApi.getDistributionJobsForApp(this.appVersion.version_id)
        })
        .then((d) => {
          this.distributionJobs = d
        })
    },
    targetList (targets) {
      return targets
        .map((t) => {
          return t.group.name
        })
        .join(', ')
    },
    localTime (time) {
      return moment(time).format('DD.MM.YYYY - HH:mm')
    },
    deleteJob (job) {
      return appsApi.deleteDistributionJob(job.job_id).then(() => {
        this.updateApps()
      })
    },
  },
  computed: {
    versionCombination () {
      return this.appVersion
        ? `${this.appVersion.version_name}(${this.appVersion.version_code})`
        : ''
    },
  },

  watch: {
    appVersion () {
      if (!this.appVersion || !this.appVersion.version_id) {
        return
      }
      this.updateApps()
    },

    value (isVisible) {
      if (!isVisible) {
        this.selectedGroups = []
        this.showWarningHint = false
      }

      if (!this.appVersion || !this.appVersion.version_id) {
        return
      }
      this.updateApps()
    },
  },
}
</script>

<style scoped>
.v-table__overflow {
  overflow-y: scroll;
  height: 500px;
}
</style>
