<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title class="headline secondary primary--text" primary-title>
        {{ state.currentTitle }}
      </v-card-title>
      <v-card-text>{{ state.currentError }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn class="secondary primary--text" text @click="show = false"
        >Ok
        </v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ErrorStore from '@/stores/ErrorStore'

export default {
  name: 'error-popup',
  data () {
    return {
      state: ErrorStore.state,
      show: false,
    }
  },
  watch: {
    state: {
      handler () {
        if (this.state.currentError) {
          this.show = true
        }
      },
      deep: true,
    },
    show (val) {
      if (!val) {
        ErrorStore.clearError()
      }
    },
  },
}
</script>
