import moment from 'moment'

export default {
  formattedDate (date) {
    if (!date) {
      return ''
    }
    const parsed = moment(date)
    return parsed.format('DD.MM.YYYY - HH:mm')
  },

  objectIsActive (
    startDateString, /*: string | null | undefined */
    endDateString /*: string | null | undefined */
  ) {
    if ((startDateString && startDateString.length > 0) && (endDateString && endDateString.length > 0)) {
      // If Object serves both dateStrings check if the moment is inbetween now
      return moment().isBetween(moment(String(startDateString)), moment(String(endDateString)))
    } else if (startDateString && startDateString.length > 0) {
      // If Object serves only start dateString check if moment is before now
      return moment(String(startDateString)).isBefore()
    } else if (endDateString && endDateString.length > 0) {
      // If Object serves only end dateString check if moment is after now
      return moment(String(endDateString)).isAfter()
    } else {
      // If Object serves no dateString it is not constraint and always active
      return true
    }
  },
}
