<template>
  <form-dialog
    :can-save="userCanWrite"
    :title="title"
    :value="value"
    @input="$emit('input', $event)"
    @save="save()"
  >
    <v-form
      class="pt-4"
      style="padding-left: 10%; padding-right: 10%"
      @submit.prevent="save()"
    >
      <v-container fluid grid-list-xl style="width: 80%">
        <v-layout wrap>
          <v-flex sm12>
            <h3>Stammdaten</h3>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              v-model="name"
              :disabled="!userCanWrite"
              label="Name"
              required
            />
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              v-model="packagename"
              :disabled="!userCanWrite"
              label="Paket"
            />
          </v-flex>
          <v-flex sm12></v-flex>
        </v-layout>
      </v-container>
      <v-btn style="display: none" type="submit"/>
    </v-form>
  </form-dialog>
</template>

<script>
import FormDialog from '../components/FormDialog'
import errorStore from '@/stores/ErrorStore'
import appApi from '@/api/apps'
import AuthStore from '@/stores/AuthStore'

export default {
  name: 'external-app-details',
  components: { FormDialog },
  props: ['app', 'value'],
  data () {
    return {
      name: '',
      packagename: '',
    }
  },
  computed: {
    title () {
      if (this.app) {
        return `${this.app.name} - ${this.app.packagename}`
      }
      return 'Neue App'
    },

    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },
  },
  watch: {
    app (a) {
      if (a) {
        this.name = a.name
        this.packagename = a.packagename
      } else {
        this.name = ''
        this.packagename = ''
      }
    },
  },
  methods: {
    save () {
      let call = null
      const settings = {
        name: this.name,
        packagename: this.packagename,
      }
      if (!this.app) {
        call = appApi.createExternal(settings)
      } else {
        call = appApi.updateExternal(this.app.external_app_id, settings)
      }
      call
        .then((u) => {
          this.$emit('reload')
          this.$emit('input', false)
        })
        .catch((err) => {
          errorStore.showError(err)
        })
    },
  },
}
</script>

<style scoped></style>
