const baseURL = process.env.API_URL || 'https://dev.staepp-cloud.de'
const theme = process.env.THEME || 'dev'
const version = process.env.VERSION || '1.5.0'

export default {
  adminUrl: `${baseURL}/admin`,
  apiUrl: `${baseURL}/api`,
  theme: theme,
  version: version,
}
