import base from './base'

export default {
  saveEJSwitchDate (data) {
    return base.request('ej-switch', 'POST', data)
  },

  getEJSwitchDate () {
    return base.apiRequest('ej-switch', 'GET')
  },

  saveEJFirstDrawDate (data) {
    return base.request('ej-first-draw-switch', 'POST', data)
  },

  getEJFirstDrawDate () {
    return base.apiRequest('ej-first-draw-switch', 'GET')
  },
}
