<template>
  <form-dialog
    :title="title"
    :value="value"
    @input="$emit('input', $event)"
    @save="save()"
  >
    <v-form
      style="padding-left: 10%; padding-right: 10%; padding-top: 3%"
      @submit.prevent="save()"
    >
      <v-container fluid grid-list-xl style="width: 80%">
        <v-layout wrap>
          <v-flex sm12>
            <h3>Stammdaten</h3>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              v-model="email"
              :disabled="!userCanWrite"
              label="E-Mail"
              required
            />
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              v-model="name"
              :disabled="!userCanWrite"
              label="Name"
              required
            />
          </v-flex>
          <v-flex sm6 xs12>
            <v-select
              v-model="role_id"
              :disabled="!userCanWrite"
              :items="roles"
              item-text="name"
              item-value="role_id"
              label="Rolle"
            />
          </v-flex>
          <v-flex sm6 xs12></v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
              :type="showPassword ? 'text' : 'password'"
              label="Passwort"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </v-flex>
          <v-flex sm6 xs12>
            <v-text-field
              v-model="password_confirm"
              :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
              :type="showPassword ? 'text' : 'password'"
              label="Passwort wiederholen"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-container>
      <v-btn style="display: none" type="submit"/>
    </v-form>
  </form-dialog>
</template>

<script>
import FormDialog from '../components/FormDialog'
import userApi from '@/api/user'
import errorStore from '@/stores/ErrorStore'
import AuthStore from '@/stores/AuthStore'

export default {
  name: 'user-details',
  components: { FormDialog },
  props: ['user', 'value'],
  data () {
    return {
      name: '',
      email: '',
      password: null,
      password_confirm: null,
      showPassword: false,
      role_id: null,
      roles: [],
    }
  },
  computed: {
    title () {
      if (this.user) {
        return `${this.user.name} - ${this.user.email}`
      }
      return 'Neuer Nutzer'
    },
    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },
  },
  watch: {
    user (u) {
      if (u) {
        this.name = u.name
        this.email = u.email
        this.role_id = u.role.role_id
      } else {
        this.name = ''
        this.email = ''
      }
      this.password = ''
      this.password_confirm = ''
    },
  },
  mounted () {
    userApi.getRoles().then((r) => {
      this.roles = r
    })
  },
  methods: {
    save () {
      if (!this.role_id) {
        errorStore.showError('Bitte eine Rolle auswählen')
        return
      }

      let call = null
      if (this.password && this.password !== this.password_confirm) {
        errorStore.showError('Passwörter stimmen nicht überein')
        return
      }

      const settings = {
        name: this.name,
        email: this.email,
        password: this.password ? this.password : null,
        role_id: this.role_id,
      }
      if (!this.user) {
        call = userApi.createUser(settings)
      } else {
        call = userApi.updateUser(this.user.user_id, settings)
      }
      call
        .then((u) => {
          this.$emit('reload')
          this.$emit('input', false)
        })
        .catch((err) => {
          errorStore.showError(err)
        })
    },
  },
}
</script>

<style scoped></style>
