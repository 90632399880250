import base from './base'
import sessionStore from '../stores/AuthStore'

export default {
  login (email, password) {
    return base
      .request(
        'login/',
        'POST',
        {
          email: email,
          password: password,
        },
        false
      )
      .then((authResponse) => {
        sessionStore.commit('login', authResponse)
      })
  },
  reauth () {
    return base.request('auth/', 'GET').then((reauthResponse) => {
      sessionStore.state.reauthUser(reauthResponse)
    })
  },
}
