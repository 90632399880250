<template>
  <v-container>
    <v-layout justify-space-between wrap>
      <v-flex md4 xs12>
        <h1>Login</h1>
        <v-form @submit.prevent="submit">
          <v-text-field v-model="email" label="E-Mail"></v-text-field>
          <v-text-field
            v-model="password"
            label="Password"
            type="password"
          ></v-text-field>
          <v-btn class="secondary primary--text" type="submit">Login</v-btn>
        </v-form>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script language="JavaScript">
import authApi from '../api/auth'
import ErrorStore from '@/stores/ErrorStore'

export default {
  name: 'Login',
  data: () => {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    submit () {
      authApi
        .login(this.email, this.password)
        .then((data) => {
          this.$router.push('/device')
        })
        .catch((err) => {
          ErrorStore.showError(err)
        })
    },
  },
}
</script>
