<template>
  <tr :key="maintenance.id" :class="textClass" style="cursor: pointer">
    <td>{{ formattedStart }}</td>
    <td>{{ formattedEnd ? formattedEnd : '-' }}</td>
    <td>{{ maintenance.info }}</td>
    <td>
      <i v-if="maintenance.active" class="fas fa-check"/>
      <i v-else class="fas fa-times"/>
    </td>
    <td>
      <v-btn icon>
        <v-icon class="primary--text" @click="editMaintenance">fa-edit</v-icon>
      </v-btn>
    </td>
    <td>
      <v-btn icon>
        <v-icon class="primary--text" @click="deleteMaintenance"
        >fa-trash
        </v-icon
        >
      </v-btn>
    </td>
  </tr>
</template>

<script>
import timeUtil from '@/utils/time'

export default {
  props: ['maintenance'],
  name: 'MaintenanceListRow',
  methods: {
    editMaintenance () {
      this.$emit('edit', this.maintenance)
    },
    deleteMaintenance () {
      this.$emit('delete', this.maintenance)
    },
  },
  computed: {
    formattedStart () {
      return timeUtil.formattedDate(this.maintenance.start)
    },
    formattedEnd () {
      return timeUtil.formattedDate(this.maintenance.end)
    },
    textClass () {
      return this.maintenance.active ? 'black--text' : 'grey--text'
    },
  },
}
</script>

<style scoped></style>
