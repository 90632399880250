<template>
  <form-dialog
    :can-save="userCanWrite"
    :title="title"
    :value="value"
    @input="close($event)"
    @save="save"
  >
    <v-layout class="pt-4" style="padding-left: 10%; padding-right: 10%">
      <v-card color="#F5F5F5" elevation="0" style="width: 100%">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          color="#E32119"
          fixed-tabs
        >
          <v-layout class="justify-space-between">
            <v-tab v-for="item in items" :key="item" style="width: 50%">
              {{ item }}
            </v-tab>
          </v-layout>
        </v-tabs>
        <v-divider/>
      </v-card>
    </v-layout>

    <v-layout style="padding-left: 10%; padding-right: 10%">
      <v-flex>
        <v-card class="mt-4">
          <v-tabs-items v-model="tab">
            <v-tab-item :key="items[0]">
              <v-form>
                <v-card flat>
                  <v-container fluid grid-list-xl style="width: 90%">
                    <v-layout wrap>
                      <v-flex sm12>
                        <h3>Stammdaten</h3>
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          v-model="serialnumber"
                          :disabled="mutableDevice !== null"
                          label="Seriennummer"
                          required
                        />
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          v-model="annahmestellennr"
                          disabled
                          label="Annahmestelle"
                          required
                        />
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          :value="firstAccess"
                          disabled
                          label="Erste Anmeldung"
                          required
                        />
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          v-model="lastAccess"
                          disabled
                          label="Letzte Anfrage"
                        />
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          v-model="astInfo"
                          :disabled="!userCanWrite"
                          label="AST Zusatzinfo"
                          required
                        />
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          :value="mutableDevice ? mutableDevice.ip : ''"
                          disabled
                          label="IP-Adresse"
                          required
                        />
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          :value="
                            mutableDevice ? mutableDevice.firmware_version : ''
                          "
                          disabled
                          label="Firmwareversion"
                          required
                        />
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          :value="firmwareVersionLastUpdate"
                          disabled
                          label="Letzte Aktualisierung der Firmwareversion"
                          required
                        />
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          :value="
                            mutableDevice ? mutableDevice.android_version : ''
                          "
                          disabled
                          label="Android Version"
                          required
                        />
                      </v-flex>
                      <v-flex sm6 xs12></v-flex>
                      <v-flex sm6 xs12>
                        <v-select
                          v-model="group_id"
                          :disabled="!userCanWrite"
                          :items="groups"
                          item-text="name"
                          item-value="group_id"
                          label="Gruppe"
                        >
                        </v-select>
                      </v-flex>
                      <v-flex sm6 xs12>
                        <v-text-field
                          :value="
                            mutableDevice ? mutableDevice.cloud_version : ''
                          "
                          disabled
                          label="Installierte STÄPP Cloud Version"
                          required
                        />
                      </v-flex>
                      <v-flex v-if="mutableDevice" sm12>
                        <v-checkbox
                          v-model="mutableDevice.manual_opening_times"
                          :disabled="!userCanWrite"
                          label="Öffnungszeiten manuell bestimmen"
                        />
                      </v-flex>
                      <template
                        v-if="
                          mutableDevice && mutableDevice.manual_opening_times
                        "
                      >
                        <v-flex sm12>
                          <v-card>
                            <v-container>
                              <h3>Achtung</h3>
                              <p>
                                Wenn diese Funktion aktiviert ist, werden
                                Öffnungs- und Urlaubszeiten dieses Geräts nicht
                                mehr automatisch über den Webservice
                                aktualisiert
                              </p>
                              <p>
                                Die Öffnungszeiten müssen das gleiche Format wie
                                von der CSV vorgegeben haben und vollständig
                                sein, z.b.:
                                <strong
                                >Mo: Geschlossen,Di: 14:00-18:00,Mi:
                                  08:00-18:00,Do: 08:00-18:00,Fr:
                                  08:00-19:00,Sa: 08:00-15:00,So:
                                  Geschlossen</strong
                                >
                              </p>
                              <p>
                                Die Urlaubszeiten können leer sein oder das
                                CSV-Format annehmen, z.b.:
                                <strong>20190609,20190611</strong>
                              </p>
                            </v-container>
                          </v-card>
                        </v-flex>
                        <v-flex sm12>
                          <v-text-field
                            v-model="mutableDevice.opening_times"
                            :append-outer-icon="manualTimesBadge"
                            :disabled="!userCanWrite"
                            label="Öffnungszeiten"
                          />
                        </v-flex>
                        <v-flex sm12>
                          <v-text-field
                            v-model="mutableDevice.vacation"
                            :disabled="!userCanWrite"
                            label="Urlaub"
                          />
                        </v-flex>
                      </template>
                      <v-flex
                        v-if="
                          mutableDevice &&
                          (mutableDevice.manual_opening_times ||
                            mutableDevice.annahmestelle)
                        "
                        sm6
                        xs12
                      >
                        <h3>Öffnungszeiten</h3>
                        <v-list>
                          <v-list-item v-for="time in openingTimes" :key="time">
                            <v-list-item-content>
                              {{ time }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-flex>
                      <v-flex
                        v-if="
                          mutableDevice &&
                          (mutableDevice.manual_opening_times ||
                            mutableDevice.annahmestelle)
                        "
                        sm6
                        xs12
                      >
                        <h3>Urlaub</h3>
                        <v-list>
                          <v-list-item>
                            {{ displayVacation }}
                          </v-list-item>
                        </v-list>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-form>
            </v-tab-item>

            <v-tab-item :key="items[1]">
              <v-form>
                <v-container fluid grid-list-xl style="width: 90%">
                  <v-layout class="pt-2" wrap>
                    <v-flex sm12>
                      <h3>Einstellungen</h3>
                    </v-flex>
                    <v-flex sm12>
                      <group-warning
                        :device-is-in-group="
                          disableConfig && mutableDevice && mutableDevice.group
                        "
                        :group-name="
                          mutableDevice && mutableDevice.group
                            ? mutableDevice.group.name
                            : ''
                        "
                        :show-group-config="showGroupConfig"
                      />
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-subheader class="pl-0">Helligkeit</v-subheader>
                      <v-slider
                        v-model="settings.brightness"
                        :disabled="disableConfig"
                        max="1"
                        min="0"
                        step="0.05"
                        thumb-label="always"
                      />
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-subheader class="pl-0">Lautstärke</v-subheader>
                      <v-slider
                        v-model="settings.volume"
                        :disabled="disableConfig"
                        max="1"
                        min="0"
                        step="0.05"
                        thumb-label="always"
                      />
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-text-field
                        v-if="userCanWrite"
                        v-model="settings.password"
                        :append-icon="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                        :disabled="disableConfig"
                        :type="showPassword ? 'text' : 'password'"
                        label="Passwort"
                        required
                        @click:append="requestShowPassword()"
                      />
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-text-field
                        v-model="settings.refresh_minutes"
                        :disabled="disableConfig"
                        :rules="intervalRules"
                        label="Abfrageintervall (Minuten)"
                      >
                        required
                      </v-text-field>
                    </v-flex>
                    <v-flex sm6 xs12>
                      <v-checkbox
                        v-model="settings.location_service"
                        :disabled="disableConfig"
                        label="Ortungsdienste"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-tab-item>
            <v-tab-item :key="items[2]">
              <v-form>
                <v-container fluid grid-list-xl style="width: 90%">
                  <v-layout wrap>
                    <v-flex sm12>
                      <h3>Apps</h3>
                    </v-flex>
                    <v-flex sm12>
                      <group-warning
                        :device-is-in-group="
                          disableConfig && mutableDevice && mutableDevice.group
                        "
                        :group-name="
                          mutableDevice && mutableDevice.group
                            ? mutableDevice.group.name
                            : ''
                        "
                        :show-group-config="showGroupConfig"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>

                <permission-list
                  :apps="apps"
                  :disabled="disableConfig"
                  :editedInstallList="editedInstallList"
                  :externalWhiteList="externalWhiteList"
                  :installList="installList"
                  :installedList="installedApps"
                />
              </v-form>
            </v-tab-item>

            <v-tab-item :key="items[3]">
              <v-container v-if="mutableDevice" style="width: 90%">
                <v-list subheader two-line>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-subheader class="body-2 font-weight-medium"
                      >Bezeichnung
                      </v-subheader
                      >
                    </v-flex>
                  </v-layout>
                  <v-divider/>
                  <installed-apps-tile
                    v-for="app in installedApps"
                    :key="app.packagename"
                    :app="app"
                    :disabled="disableConfig"
                  />
                </v-list>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-flex>
    </v-layout>
  </form-dialog>
</template>

<script>
import moment from 'moment'

import astApi from '@/api/device'
import appApi from '@/api/apps'
import groupsApi from '@/api/groups'
import FormDialog from '../components/FormDialog'
import ErrorStore from '@/stores/ErrorStore'
import AuthStore from '@/stores/AuthStore'
import timeUtils from '@/utils/time'
import GroupWarning from '@/components/Devices/GroupWarning'
import InstalledAppsTile from '../components/Devices/InstalledAppsTile'
import PermissionList from '../components/Devices/PermissionList'

export default {
  name: 'device-details',
  components: {
    GroupWarning,
    FormDialog,
    PermissionList,
    InstalledAppsTile
  },
  data () {
    return {
      showPassword: false,
      tab: null,
      items: ['Stammdaten', 'Einstellungen', 'Apps', 'Installierte Apps'],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      intervalRules: [
        (v) => !!v || 'Intervall wird benötigt',
        (v) => /^[0-9]+$/.test(v) || 'Bitte nur ganze Zahlen eingeben',
      ],
      groups: [],
      astInfo: '',
      settings: {},
      group_id: null,
      serialnumber: '',
      annahmestellennr: '',
      mutableDevice: null,
      installedApps: [],
      externalApps: [],
      apps: [],
      manualTimesOk: null,
      externalWhiteList: null,
      installList: null,
      editedInstallList: [],
    }
  },
  props: {
    value: Boolean,
    device: Object,
  },
  computed: {
    title () {
      if (!this.mutableDevice) {
        return ''
      }
      if (this.mutableDevice.annahmestelle) {
        return `${this.mutableDevice.serialnumber} - ${this.mutableDevice.annahmestelle.annahmestellennr}`
      }
      return `${this.mutableDevice.serialnumber}`
    },

    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },
    disableConfig () {
      return (
        (this.mutableDevice && !!this.group_id) ||
        (this.mutableDevice === null && !!this.group_id) ||
        !this.userCanWrite
      )
    },
    defaultGroupId () {
      if (this.groups.length === 0) {
        return null
      }
      const default_groups = this.groups.filter((g) => {
        return g.is_default
      })
      if (default_groups.length === 0) {
        return null
      } else {
        return default_groups[0].group_id
      }
    },
    firstAccess () {
      return this.mutableDevice
        ? timeUtils.formattedDate(this.mutableDevice.first_access)
        : ''
    },
    lastAccess () {
      return this.mutableDevice
        ? timeUtils.formattedDate(this.mutableDevice.last_access)
        : ''
    },
    firmwareVersionLastUpdate () {
      return this.mutableDevice
        ? timeUtils.formattedDate(
          this.mutableDevice.firmware_version_last_update
        )
        : ''
    },
    openingTimes () {
      if (!this.mutableDevice) {
        return []
      }
      if (
        this.mutableDevice.manual_opening_times &&
        this.mutableDevice.opening_times
      ) {
        return this.mutableDevice.opening_times.split(',')
      }
      if (!this.mutableDevice.annahmestelle) {
        return []
      }
      if (!this.mutableDevice.annahmestelle.opening_times) {
        return []
      }
      return this.mutableDevice.annahmestelle.opening_times.split(',')
    },
    // eslint-disable-next-line vue/return-in-computed-property
    displayVacation () {
      if (
        this.mutableDevice !== null &&
        this.mutableDevice.manual_opening_times
      ) {
        if (!this.mutableDevice.vacation) {
          return ''
        }
        const vacationDates = this.mutableDevice.vacation.split(',')
        const vacationMoments = vacationDates.map((v) => {
          return moment(v, 'YYYYMMDD')
        })
        return vacationMoments
          .map((v) => {
            return v.format('DD.MM.YYYY')
          })
          .join(' - ')
      }

      if (
        !this.mutableDevice ||
        !this.mutableDevice.annahmestelle ||
        !this.mutableDevice.annahmestelle.vacation
      ) {
        return ''
      }
      const vacationDates =
        this.mutableDevice.annahmestelle.vacation.split(',')
      const vacationMoments = vacationDates.map((v) => {
        return moment(v, 'YYYYMMDD')
      })
      return vacationMoments
        .map((v) => {
          return v.format('DD.MM.YYYY')
        })
        .join(' - ')
    },
    manualTimesBadge () {
      return this.manualTimesOk == null
        ? ''
        : this.manualTimesOk === true
          ? 'fa-check-circle'
          : 'fa-times-circle'
    },
  },
  mounted () {
    groupsApi
      .getAll()
      .then((g) => {
        this.groups = [
          {
            group_id: null,
            name: '- Keine -',
          },
        ]
        this.groups.push(...g)
        appApi.getAll().then((a) => {
          this.apps = a
        })
        appApi.getAllExternal().then((a) => {
          this.externalApps = a
        })
      })
      .then((a) => {
        this.updateDevice()
      })
  },
  watch: {
    device () {
      this.updateDevice()
    },
    mutableDevice () {
      if (this.mutableDevice != null) {
        appApi
          .getAllInstalled(this.mutableDevice.device_id)
          .then((installed) => {
            this.installedApps = installed
          })
      }
      this.updateDevice()
    },
    value () {
      this.updateDevice()
    },
    'mutableDevice.opening_times' (active) {
      if (active) {
        this.checkManualTimes()
      }
    },
    apps (allApps) {
      if (
        this.installList.length === 0 &&
        this.editedInstallList.length === 0 &&
        allApps.length > 0
      ) {
        this.editedInstallList = allApps.map((app) => {
          return {
            app: app.packagename,
            version: {
              version_code: null,
              displayTitle: 'Keine',
            },
          }
        })
      }
    },
    installList (newInstallList) {
      if (this.editedInstallList.length === 0 && newInstallList.length > 0) {
        this.editedInstallList = newInstallList.map((app) => {
          return {
            app: app.packagename,
            version: app.version_code,
          }
        })
      }
    },
  },
  methods: {
    showGroupConfig () {
      this.$emit('input', false)
      this.$router.push(`/groups/${this.group_id}`)
    },
    updateDevice () {
      if (this.device) {
        this.mutableDevice = this.device
        this.settings = this.mutableDevice.settings
        this.group_id = this.mutableDevice.group_id
        this.astInfo = this.mutableDevice.ast_info
        this.serialnumber = this.mutableDevice.serialnumber
        this.annahmestellennr = this.mutableDevice.annahmestelle
          ? this.mutableDevice.annahmestelle.annahmestellennr
          : ''
        this.externalWhiteList = this.mutableDevice.apps.whitelist
        this.installList = this.mutableDevice.apps.install
      } else {
        this.astInfo = ''
        this.settings = {}
        this.group_id = this.defaultGroupId
        this.astInfo = ''
        this.serialnumber = ''
        this.annahmestellennr = ''
        this.externalWhiteList = []
        this.installList = []
      }
    },
    close (event = null) {
      this.$emit('input', event)
      this.$emit('reload')
      this.mutableDevice = null
      this.installList = null
      this.editedInstallList = []
      this.externalWhiteList = []
    },
    save () {
      if (
        this.mutableDevice &&
        this.mutableDevice.manual_opening_times &&
        !this.manualTimesOk
      ) {
        ErrorStore.showErrorMessage(
          'Bitte korrigieren Sie die Öffnungszeiten, bevor Sie mit dem Speichern fortfahren.'
        )
        return
      }
      const submitSettings = {
        ast_info: this.astInfo,
        brightness: this.settings.brightness,
        volume: this.settings.volume,
        password: this.settings.password,
        refresh_minutes: this.settings.refresh_minutes,
        location_service: this.settings.location_service,
        manual_opening_times:
          this.mutableDevice !== null
            ? this.mutableDevice.manual_opening_times
            : null,
        opening_times:
          this.mutableDevice !== null ? this.mutableDevice.opening_times : null,
        vacation:
          this.mutableDevice !== null ? this.mutableDevice.vacation : null,
        group_id: this.group_id,
        serialno: this.mutableDevice === null ? this.serialnumber : null,
      }

      if (!this.mutableDevice) {
        astApi.create(submitSettings).then((r) => {
          let deviceId = r.device_id
          astApi.getDetails(deviceId).then(() => {
            this.updateDeviceInfo(deviceId, submitSettings, () => {
              this.close()
            })
          })
        })
      } else {
        let deviceId = this.mutableDevice.device_id
        this.updateDeviceInfo(deviceId, submitSettings)
      }
    },
    requestShowPassword () {
      if (this.userCanWrite) {
        this.showPassword = !this.showPassword
      } else {
        ErrorStore.showErrorMessage(
          'Sie haben keine Berechtigung das Passwort anzuzeigen.'
        )
      }
    },
    checkManualTimes () {
      if (
        this.mutableDevice &&
        this.mutableDevice.manual_opening_times &&
        this.mutableDevice.opening_times
      ) {
        astApi
          .checkManualTimes(this.mutableDevice.opening_times)
          .then((r) => {
            this.manualTimesOk = true
          })
          .catch((err) => {
            this.manualTimesOk = false
          })
      }
    },
    async doUpdateDevice (deviceId, submitSettings) {
      if (this.group_id) {
        await astApi.update(deviceId, submitSettings)
      } else {
        await astApi
          .update(deviceId, submitSettings)
          .then(async () => {
            await astApi.updateExternalPermissions(
              deviceId,
              this.externalWhiteList
            )
          })
          .then(async () => {
            await astApi.updatePermissions(deviceId, this.editedInstallList)
          })
          .then(async () => {
            await astApi.getDetails(deviceId)
          })
      }
    },
    async updateDeviceInfo (deviceId, submitSettings, callback = () => {
    }) {
      await this.doUpdateDevice(deviceId, submitSettings)
        .then(() => {
          ErrorStore.showMessage('Änderungen gespeichert', 'Erfolg')
          this.$emit('reload')
          callback()
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
  },
}
</script>
