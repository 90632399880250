<template>
  <div>
    <v-dialog v-model="showDetails" width="800">
      <v-card v-if="reportDetails">
        <v-card-title class="headline secondary primary--text" primary-title>
          {{ reportedAt }} - {{ reportDetails.device.serialnumber }}
        </v-card-title>
        <v-card-text>{{ reportDetails.content }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="secondary primary--text"
            text
            @click="showDetails = false"
          >Ok
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet class="pa-3 primary lighten-2">
      <v-select
        v-model="selectedType"
        :items="reportTypes"
        dark
        item-text="name"
        item-value="value"
      ></v-select>
    </v-sheet>
    <v-container>
      <v-data-table
        :footer-props="footerProps"
        :headers="tableHeaders"
        :items="displayReports"
        :options.sync="tableOptions"
        class="elevation-1"
        @pagination="paginationChanged"
      >
        <template v-slot:item="props">
          <report-list-row
            :report="props.item"
            @click="reportClicked"
          ></report-list-row>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import deviceApi from '@/api/device'
import ErrorStore from '@/stores/ErrorStore'
import ReportListRow from '../components/Devices/ReportListRow'
import timeUtils from '@/utils/time'
import ReportsApi from '@/api/reports'

export default {
  name: 'device-reports',
  components: { ReportListRow },
  data () {
    return {
      reportTypes: [
        {
          value: null,
          name: 'Alle Apps'
        },
        {
          value: 'staepp-cloud',
          name: 'STÄPP Cloud'
        },
        {
          value: 'staepp',
          name: 'STÄPP'
        },
        {
          value: 'oddset',
          name: 'Oddset'
        },
      ],
      selectedType: null,
      device: {},
      reports: [],
      reportDetails: null,
      showDetails: false,
      footerProps: {
        'items-per-page-options': [5, 10, 15, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
        sortBy: ['reported_at'],
        sortDesc: [true],
      },
    }
  },
  mounted () {
    deviceApi
      .getDetails(this.$route.params.id)
      .then((d) => {
        this.device = d
        return deviceApi.getReports(d.device_id)
      })
      .then((r) => {
        this.reports = r
      })
      .catch((err) => {
        ErrorStore.showError(err)
      })
  },
  methods: {
    reportClicked (report) {
      ReportsApi.getReport(report.report_id).then((r) => {
        this.reportDetails = r
        this.showDetails = true
      })
    },
    itemsPerPage () {
      return parseInt(localStorage.getItem('detailsItemsPerPage')) || 15
    },
    paginationChanged (pagination) {
      localStorage.setItem('detailsItemsPerPage', pagination.itemsPerPage)
    },
  },
  computed: {
    reportedAt () {
      return this.reportDetails
        ? timeUtils.formattedDate(this.reportDetails.reported_at)
        : ''
    },
    displayReports () {
      const reports = this.reports

      if (this.selectedType === null) {
        return reports
      }
      const validTypes = {
        staepp: ['staepp'],
        'staepp-cloud': ['staepp-cloud', 'crash'],
        oddset: ['oddset'],
      }
      const types = validTypes[this.selectedType]
      return reports.filter((r) => {
        return types.includes(r.type)
      })
    },
    tableHeaders () {
      return [
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: 'App',
          value: null,
          sortable: false
        },
        {
          text: 'Datum',
          value: 'reported_at'
        },
        {
          text: 'Gerät',
          value: 'device.serialnumber'
        },
        {
          text: 'Annahmestelle',
          value: 'device.annahmestelle.annahmestellennr',
        },
      ]
    },
  },
}
</script>

<style scoped></style>
