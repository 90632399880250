import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

Vue.use(Vuex)
const sessionStore = new Vuex.Store({
  state: {
    apiToken: null,
    user: null,
    hasWriteAccess () {
      if (!this.user || !this.user.role) {
        return false
      }
      return ['admin', 'qa'].includes(this.user.role.identifier)
    },
    hasDocUploadAccess () {
      if (!this.user || !this.user.role) {
        return false
      }
      return ['admin', 'editor', 'qa'].includes(this.user.role.identifier)
    },
    isEditor () {
      if (!this.user || !this.user.role) {
        return false
      }
      return ['editor'].includes(this.user.role.identifier)
    },
    isAdmin () {
      if (!this.user || !this.user.role) {
        return ['admin', 'qa'].includes(this.user.role.identifier)
      }
      return ['editor'].includes(this.user.role.identifier)
    },
    isMobiPrivate () {
      if (!this.user || !this.user.email) {
        return false
      }
      return this.user.email.includes('@mobivention.com')
    },
    reauthUser (reauthResponse) {
      this.user = reauthResponse.user
      this.apiToken = reauthResponse.auth_token
    },
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => Cookies.get(key),
        // save cookie for 7 days
        setItem: (key, value) => Cookies.set(key, value, { expires: 7 }),
        removeItem: (key) => Cookies.remove(key),
      },
    }),
  ],
  mutations: {
    logout (state) {
      state.apiToken = null
      state.user = null
    },
    login (state, authResponse) {
      state.user = authResponse.user
      state.apiToken = authResponse.auth_token
    },
  },
})

export default sessionStore
