import base from './base'

export default {
  getLogs () {
    return base.request('logs')
  },

  deleteLogs (settings) {
    return base.request('delete_logs', 'POST', settings)
  },
}
