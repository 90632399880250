<template>
  <v-list-item
    :class="[link === $route.path ? navHighlight : '']"
    @click="clicked"
  >
    <v-list-item-icon>
      <v-icon :class="menuText">{{ icon }}</v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title :class="menuText">
        {{ title }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import config from '@/config'

export default {
  methods: {
    clicked () {
      if (this.link) {
        this.$router.push(this.link)
      }
    },
  },
  props: ['title', 'icon', 'link'],
  computed: {
    menuText () {
      if (config.theme === 'test' || config.theme === 'dev') {
        return 'secondary--text'
      }
      return 'primary--text'
    },
    navHighlight () {
      if (config.theme === 'dev' || config.theme === 'test') {
        return 'black'
      } else {
        return 'lime'
      }
    },
  },
}
</script>

<style scoped>
.v-list-item__icon {
  max-width: 24px;
}
</style>
