<template>
  <div>
    <div v-if="deviceIsInGroup">
      <v-layout>
        <v-card-text class="grey--text darken-2 font-weight-medium"
        >Gruppenkonfiguration
        </v-card-text
        >
      </v-layout>

      <v-layout>
        <v-flex xs9>
          <v-card-text>
            Konfiguration und freigegebene Apps werden von Gruppe‏‏‎ ‎<strong>{{
              groupName
            }}</strong
          >‏‏‎ ‎übernommen.
          </v-card-text>
        </v-flex>

        <v-flex text-xs-right xs3>
          <v-card-text
            class="primary--text font-weight-medium ml-3"
            style="text-decoration: underline; cursor: pointer"
            @click="showGroupConfig"
          >
            Zur Gruppenkonfiguration
          </v-card-text>
        </v-flex>
      </v-layout>
    </div>
    <div v-else>
      <h3>Achtung</h3>
      <p>
        Dieses Gerät ist keiner Gruppe zugeordnet. Alle Einstellungen die an
        dieser Stelle vorgenommen werden, gelten nur für dieses Gerät und werden
        an keiner anderen Stelle übernommen
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'group-warning',
  props: ['deviceIsInGroup', 'groupName', 'showGroupConfig'],
}
</script>
