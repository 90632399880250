<template>
  <v-container fluid>

    <LotDialog @close="reload"/>

    <v-card>
      <v-data-table
        :footer-props="footerProps"
        :headers="tableHeaders"
        :items="lots"
        :options.sync="tableOptions"
        class="elevation-1"
        fixed-header
        @pagination="paginationChanged"
      >
        <template v-slot:top>
          <v-row no-gutters>
            <div class="ms-4 mt-2">
              <v-icon color="info">mdi-information</v-icon>
              <span
                class="ms-2 subtitle-2"
                v-html="'Sortierung für Anzeige in App mittels drag & drop.<br/>Anzeige nach Gittermuster: Gerade Nummern -> linke Spalte, ungerade Nummern -> rechte Spalte.'"/>
            </div>

            <v-spacer></v-spacer>
            <div class="d-flex flex-row">
              <v-card-text class="primary--text text-right font-weight-bold">
                Neues Los
              </v-card-text>
              <v-btn
                class="primary elevation-0 mt-1 me-1"
                fab
                style="width: 50px; height: 50px"
                @click="showLotDialog(true, null)"
              >
                <!-- Style needed for display bug on firefox -->
                <v-icon class="white--text" style="display: inline-flex">
                  fa-plus
                </v-icon>
              </v-btn>
            </div>

          </v-row>
        </template>

        <template v-slot:body="props">
          <LotListRow
            :allow-interaction="true"
            :lots="props.items"
            @delete="deleteLot"
            @drop="onDropEvent"
            @edit="(item) => showLotDialog(false, item)"/>
        </template>

      </v-data-table>
    </v-card>

  </v-container>
</template>

<script>
import LotApi from '@/api/lots'
import ErrorStore from '@/stores/ErrorStore'
import QuestionStore from '@/stores/QuestionStore'
import LotListRow from '@/components/Lots/LotListRow'
import LotDialog from '@/components/Lots/LotDialog'
import LotStore from '@/stores/LotStore'

export default {
  name: 'all-lots',
  components: {
    LotDialog,
    LotListRow
  },
  data () {
    return {
      lots: [],
      footerProps: {
        'items-per-page-options': [5, 10, 15, -1],
      },
      tableOptions: {
        itemsPerPage: 10,
      },
      wHeight: 0,
    }
  },
  methods: {
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
    reload () {
      LotApi.getAll().then((d) => {
        this.lots = d
      })
    },
    showLotDialog (isNew, campaign) {
      LotStore.commit(
        'showLotDialog',
        /* data */{
          lot: campaign,
          isNew: isNew
        }
      )
    },
    deleteLot (lot) {
      QuestionStore.poseQuestion(
        `Los "${lot.name}" wirklich löschen?`,
        ['Nein', 'Ja'],
        0
      ).then((idx) => {
        if (idx === 1) {
          LotApi.delete(lot.id)
            .then((_) => {
              ErrorStore.showErrorMessage('Los gelöscht', 'Erfolg')
              this.reload()
            })
            .catch((err) => {
              ErrorStore.showError(err)
            })
        }
      })
    },
    onDropEvent (ids/* : Array<number | null> */) {
      LotApi.sort({
        lot_ids: ids.filter(Number)
      }).then((d) => {
        this.lots = d
      })
    }
  },
  mounted () {
    this.reload()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  computed: {
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    tableHeaders () {
      return [
        {
          text: 'Position',
          value: 'order',
          sortable: false,
        },
        {
          text: 'Bild',
          value: 'files.url',
          sortable: false
        },
        {
          text: 'Losinformationen',
          value: '',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: 'Verfügbar ab',
          values: 'publish_at',
          sortable: false
        },
        {
          text: 'Verfügbar bis',
          values: 'publish_until',
          sortable: false
        },
        // TODO actual values naming
        {
          text: 'Hintergrundfarbe',
          align: 'center',
          values: 'color'
        },
        {
          text: 'Letzte Aktualisierung',
          value: 'modified_at',
          sortable: false
        },
        {
          text: '',
          sortable: false
        },
      ]
    }
  },
  watch: {
    windowHeight (height) {
      this.wHeight = height
    },
  },
}
</script>

<style>
.theme--light.v-text-field--box > .v-input__control > .v-input__slot {
  background: white;
}
</style>
