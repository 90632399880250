import base from './base'

export default {
  getAll () {
    return base.request('groups/')
  },

  getDetails (id) {
    return base.request(`groups/${id}`)
  },

  getDefault () {
    return base.request('groups/default')
  },

  update (id, settings) {
    return base.request(`groups/${id}`, 'PUT', settings)
  },

  create (settings) {
    return base.request('groups/', 'POST', settings)
  },

  delete (id) {
    return base.request(`groups/${id}`, 'DELETE')
  },

  updatePermissions (id, settings) {
    return base.request(`groups/${id}/permissions`, 'PUT', settings)
  },

  updateExternalPermissions (id, settings) {
    return base.request(`groups/${id}/external_permissions`, 'PUT', settings)
  },
}
