import AuthStore from '@/stores/AuthStore'
import config from '@/config'

const baseURL = config.adminUrl
const apiUrl = config.apiUrl
export default {
  rawRequest (path, method, data, authed, headers) {
    if (!headers) {
      headers = {
        'Content-Type': 'application/json',
        Accepts: 'application/json',
      }
    }
    if (method === undefined) {
      method = 'GET'
    }
    if (authed) {
      headers['X-Auth-Token'] = AuthStore.state.apiToken
    }
    const url = `${baseURL}/${path}`
    return fetch(url, {
      method: method,
      mode: 'cors',
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    })
  },

  xmlRequest (path, method, data, authed, headers) {
    if (!headers) {
      headers = {
        'Content-Type': 'image/svg+xml',
        Accepts: 'image/svg+xml',
      }
    }
    if (method === undefined) {
      method = 'GET'
    }
    if (authed) {
      headers['X-Auth-Token'] = AuthStore.state.apiToken
    }
    const url = `${baseURL}/${path}`
    return fetch(url, {
      method: method,
      mode: 'cors',
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    })
  },

  request (path, method, data, authed) {
    if (authed === undefined) {
      authed = true
    }

    let headers = {
      'Content-Type': 'application/json',
      Accepts: 'application/json',
    }

    return this.rawRequest(path, method, data, authed, headers).then((r) => {
      if (!r.ok) {
        throw r.json()
      }
      return r.json()
    })
  },

  requestXML (path, method, data, authed) {
    if (authed === undefined) {
      authed = true
    }

    const headers = {
      'Content-Type': 'image/svg+xml',
      Accepts: 'image/svg+xml',
    }

    return this.xmlRequest(path, method, data, authed, headers).then((r) => {
      if (!r.ok) {
        throw r.blob()
      }
      return r.blob()
    })
  },

  apiRequest (path, method, data, authed, headers) {
    if (!headers) {
      headers = {
        'Content-Type': 'application/json',
        Accepts: 'application/json',
      }
    }
    if (method === undefined) {
      method = 'GET'
    }
    if (authed) {
      headers['X-Auth-Token'] = AuthStore.state.apiToken
    }
    const url = `${apiUrl}/${path}`
    return fetch(url, {
      method: method,
      mode: 'cors',
      headers: headers,
      body: data ? JSON.stringify(data) : null,
    }).then((r) => {
      if (!r.ok) {
        throw r.json()
      }
      return r.json()
    })
  },
}
