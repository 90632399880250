<template>
  <div>
    <app-details
      v-model="detailsDialog"
      :app="appDetails"
      @reload="reload"
    ></app-details>
    <upload-dialog v-model="uploading" @reload="reload"></upload-dialog>
    <v-sheet class="pa-3 primary lighten-2">
      <v-text-field
        v-model="search"
        clear-icon="mdi-close-circle-outline"
        clearable
        dark
        flat
        hide-details
        label="Apps durchsuchen"
        prepend-inner-icon="fa-search"
        solo-inverted
      ></v-text-field>
    </v-sheet>
    <v-container fluid>
      <v-list subheader two-line>
        <v-subheader inset>Apps</v-subheader>
        <app-list-tile
          v-for="item in displayApps"
          :key="item.app_id"
          :item="item"
          :showTrash="userCanWrite"
          @click="showDetails"
          @delete="deleteApp"
          v-on:reload="reload"
        />
      </v-list>
      <v-btn
        v-if="userCanWrite"
        bottom
        color="primary"
        dark
        fab
        fixed
        right
        style="margin-bottom: 50pt"
        @click="uploadApp"
      >
        <v-icon>fa-upload</v-icon>
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import appApi from '@/api/apps'
import AppListTile from '../components/Apps/AppListTile'
import AppDetails from './AppDetails'
import UploadDialog from './UploadDialog'
import QuestionStore from '@/stores/QuestionStore'
import AuthStore from '@/stores/AuthStore'
import ErrorStore from '@/stores/ErrorStore'

export default {
  name: 'all-apps',
  components: {
    UploadDialog,
    AppDetails,
    AppListTile
  },
  data () {
    return {
      apps: [],
      search: '',
      detailsDialog: false,
      appDetails: null,
      uploading: false,
    }
  },
  methods: {
    showDetails (app) {
      this.detailsDialog = true
      this.appDetails = app
    },
    uploadApp () {
      this.uploading = true
    },
    deleteApp (app) {
      QuestionStore.poseQuestion(
        `App ${app.name} wirklich inklusive aller angelegten Versionen löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein']
      ).then((answer) => {
        if (answer === 0) {
          this.reallyDeleteApp(app)
        }
      })
    },
    reallyDeleteApp (app) {
      appApi
        .deleteApp(app.app_id)
        .then(() => {
          this.reload()
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
    reload () {
      appApi.getAll().then((a) => {
        this.apps = a
        if (this.appDetails != null) {
          let counter = 0
          for (var app of this.apps) {
            if (app.app_id === this.appDetails.app_id) {
              break
            }
            counter += 1
          }
          this.appDetails = this.apps[counter]
        }
      })
    },
  },

  mounted () {
    this.reload()
  },
  computed: {
    displayApps () {
      if (this.search === '') {
        return this.apps
      }
      return this.apps.filter((a) => {
        return (
          a.name.toLowerCase().includes(this.search.toLowerCase()) ||
          a.packagename.toLowerCase().includes(this.search.toLowerCase())
        )
      })
    },

    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },
  },
}
</script>
<style lang="scss" scoped>
.v-icon {
  height: auto;
}
</style>
