<template>
  <v-card-text
    :class="textClass()"
    style="cursor: pointer"
    @click.stop="open"
  >
    <v-icon
      v-if="iconRes.length > 0"
      :color="color"
      class="pa-0"
      left
    >
      {{ iconRes }}
    </v-icon>
    {{ label }}
  </v-card-text>
</template>

<script>
import ErrorStore from '../stores/ErrorStore'

export default {
  name: 'AppHyperlink',
  data () {
    return {
      state: ErrorStore.state,
      show: false,
      timeout: 2500
    }
  },
  props: {
    label: {
      type: String,
      required: true,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    iconRes: {
      type: String,
      default: ''
    },
    underline: {
      type: Boolean,
      default: true
    },
    // should be a resolvable framework color (e.g primary, teal, info etc.)
    // if using modifier (e.g --darken-2 color is discarded)
    color: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    textClass () {
      let result = 'pa-0'
      result += ` ${this.color}--text`
      if (this.underline) {
        result += ' text-decoration-underline'
      }
      if (this.iconRes.trim().length > 0) {
        result += ' text-truncate'
      }
      return result
    },
    open () {
      if (this.url && this.url !== '') {
        window.open(this.url)
      }
    }
  }
}
</script>
