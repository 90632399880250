<template>
  <tr :key="device.serialnumber" style="cursor: pointer" @click="clicked">
    <td>
      <v-icon
        v-if="device.annahmestelle && this.device.missed_refreshes === 0"
        class="fa fa-circle"
        color="green"
      />
      <v-icon
        v-if="
          device.annahmestelle &&
          this.device.missed_refreshes > 0 &&
          this.device.missed_refreshes < 3
        "
        class="fa fa-circle"
        color="yellow"
      />
      <v-icon
        v-if="
          device.annahmestelle &&
          this.device.missed_refreshes > 0 &&
          this.device.missed_refreshes >= 3
        "
        class="fa fa-circle"
        color="red"
      />
    </td>
    <td>
      <v-btn v-if="showTrash" icon ripple @click.stop="deleteClicked">
        <v-icon class="primary--text">fa-trash</v-icon>
      </v-btn>
    </td>
    <td>
      <v-avatar color="primary" size="40">
        <v-icon dark>fa-tablet-alt</v-icon>
      </v-avatar>
    </td>
    <td>{{ device.serialnumber }}</td>
    <td>
      {{ device.annahmestelle ? device.annahmestelle.annahmestellennr : '' }}
    </td>
    <td>{{ shortAstinfo }}</td>
    <td>{{ device.ip }}</td>
    <td>{{ device.group ? device.group.name : '' }}</td>
    <td><i v-if="device.manual_opening_times" class="fa fa-check"></i></td>
    <td>{{ formattedLastAccess }}</td>
    <td>
      <v-btn
        v-if="device.report_count > 0"
        color="primary"
        ripple
        @click.stop="scrollClicked"
      >
        {{ device.report_count }}
        <v-icon right>fa-bolt</v-icon>
      </v-btn>
    </td>
  </tr>
</template>

<script>
import timeUtils from '@/utils/time'

export default {
  name: 'device-list-row',
  props: ['device', 'showTrash'],
  data () {
    return {
      astInfo: '',
    }
  },
  computed: {
    shortAstinfo () {
      if (this.device.ast_info == null) {
        return ''
      }
      if (this.device.ast_info.length > 10) {
        return this.device.ast_info.substr(0, 9) + '...'
      } else {
        return this.device.ast_info
      }
    },
    formattedLastAccess () {
      return timeUtils.formattedDate(this.device.last_access)
    },
  },
  methods: {
    clicked () {
      this.$emit('click', this.device)
    },
    scrollClicked () {
      this.$emit('action-click', this.device)
    },
    deleteClicked () {
      this.$emit('delete', this.device)
    },
  },
}
</script>

<style scoped></style>
