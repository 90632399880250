<template>
  <v-dialog :value="value" max-width="800">
    <v-card>
      <v-toolbar color="secondary">
        <v-btn color="primary" icon @click="cancel">
          <v-icon>fa-window-close</v-icon>
        </v-btn>
        <v-toolbar-title class="primary--text">Upload CSV</v-toolbar-title>
        <v-spacer/>
      </v-toolbar>

      <v-container>
        <file-upload-box
          :file-types="['.csv']"
          :url="url"
          param-name="csv"
          @uploaded="uploaded"
        />
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import FileUploadBox from '@/components/Apps/FileUploadBox'
import config from '@/config'
import errorStore from '@/stores/ErrorStore'

export default {
  name: 'csv-upload-dialog',
  data () {
    return {
      uploadResponse: null,
      url: `${config.baseUrl}/device/upload`,
    }
  },
  components: {
    FileUploadBox,
  },
  props: ['value'],
  methods: {
    uploaded (response) {
      errorStore.showMessage('CSV erfolgreich eingelesen')
      this.$emit('reload')
    },
    cancel () {
      this.$emit('input', false)
    },
  },
}
</script>
