<template>
  <v-row no-gutters>
    <v-col class="mr-3">
      <v-list-item>
        <v-layout>
          <v-list-item-avatar>
            <v-icon class="primary white--text">fa-mobile</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="body-1">{{ app.name }}</v-list-item-title>
            <v-list-item-subtitle class="body-2 font-weight-medium">{{
                app.packagename
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-layout>

        <v-list-item-action>
          <v-checkbox v-model="allowed" :disabled="disabled"/>
        </v-list-item-action>
      </v-list-item>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: 'external-app-permission-tile',
  props: {
    app: {},
    externalWhiteList: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      allowed: false,
    }
  },
  methods: {
    updateAllowed () {
      this.allowed = this.externalWhiteList.includes(this.app.packagename)
    },
  },
  mounted () {
    this.updateAllowed()
  },
  watch: {
    allowed (newVal) {
      this.externalWhiteList.filter((element) => {
        if (element === this.app.packagename) {
          this.externalWhiteList.splice(
            this.externalWhiteList.indexOf(element),
            1
          )
        }
      })
      if (newVal === true) {
        this.externalWhiteList.push(this.app.packagename)
      }
      this.updateAllowed()
    },
  },
}
</script>
