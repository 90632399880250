<template>
  <div>
    <v-dialog v-model="showDetails" width="800">
      <v-card v-if="logDetails">
        <v-card-title class="headline secondary primary--text" primary-title>
          {{ createdAt }} - {{ logDetails.serialnr }}
        </v-card-title>
        <v-card-text>
          <pre>{{ logDetails.data }}</pre>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="secondary primary--text"
            text
            @click="showDetails = false"
          >Ok
          </v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-sheet class="pa-3 primary lighten-2">
      <v-text-field
        v-model="search"
        clear-icon="mdi-close-circle-outline"
        clearable
        color="#000"
        dark
        flat
        hide-details
        label="Seriennummer oder IP des Tablets"
        prepend-inner-icon="fa-search"
        solo-inverted
      ></v-text-field>
    </v-sheet>
    <v-sheet class="pa-3 primary lighten-2">
      <v-text-field
        v-model="until_date"
        :hint="`Format: '${timeFormat}'`"
        :rules="[rules.required, rules.datetimeformat]"
        clear-icon="mdi-close-circle-outline"
        clearable
        color="#000"
        dark
        flat
        label="Datum-Bis"
        prepend-inner-icon=""
        solo-inverted
      />
    </v-sheet>
    <v-sheet class="pa-3 primary lighten-2">
      <v-text-field
        v-model="from_date"
        :hint="`Format: '${timeFormat}'`"
        :rules="[rules.datetimeformat]"
        clear-icon="mdi-close-circle-outline"
        clearable
        color="#000"
        dark
        flat
        label="Datum-Von"
        outlined
        prepend-inner-icon=""
        solo-inverted
      />
    </v-sheet>
    <v-container fluid>
      <v-data-table
        :footer-props="footerProps"
        :headers="tableHeaders"
        :height="wHeight * 0.5"
        :items="this.deviceLogs"
        :options.sync="tableOptions"
        class="elevation-1"
        fixed-header
        @pagination="paginationChanged"
      >
        <template v-slot:item="row">
          <device-logs-row :log="row.item" @click="logClicked"/>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import DeviceLogsRow from '@/components/Devices/DeviceLogsRow'
import deviceLogsApi from '@/api/device_logs'
import timeUtils from '@/utils/time'
import moment from 'moment'

export default {
  name: 'device-logs',
  components: { DeviceLogsRow },
  data () {
    return {
      deviceLogs: [],
      search: '',
      until_date: moment().set({
        h: 23,
        m: 59
      }).format('DD.MM.YYYY - HH:mm'),
      from_date: '',
      timeFormat: 'DD.MM.YYYY - HH:mm',
      logDetails: null,
      showDetails: false,
      fab: false,
      rules: {
        required: (value) => !!value || 'erfolderlich',
        datetimeformat: (value) => {
          const parsedTime = moment(value, this.timeFormat, true).isValid()
          if (!parsedTime) {
            return `Datum muss folgendem Format entsprechen: ${this.timeFormat}`
          }
          return true
        },
      },
      footerProps: {
        'items-per-page-options': [20, 50, 100, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      wHeight: 0,
    }
  },
  methods: {
    logClicked (log) {
      deviceLogsApi
        .getDeviceLogData({ _id: log._id })
        .then((details) => {
          this.logDetails = details
          this.logDetails.serialnr = log.serialnr
          this.showDetails = true
        })
        .catch()
    },
    applySearch () {
      this.deviceLogs = []
      deviceLogsApi
        .getDeviceLogs({
          serialnr_or_ip: this.search,
          until_date: this.until_date,
          from_date: this.from_date,
        })
        .then((a) => {
          this.deviceLogs = a
        })
        .catch((err) => {
        })
    },

    reload () {
      this.deviceLogs = []
      deviceLogsApi
        .getDeviceLogs({
          serialnr_or_ip: this.search,
          from_date: this.from_date,
          to_date: this.until_date,
        })
        .then((a) => {
          this.deviceLogs = a
        })
    },
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
  },
  mounted () {
    this.reload()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  watch: {
    windowHeight (height) {
      this.wHeight = height
    },
    search: function () {
      this.applySearch()
    },
    from_date: function () {
      this.applySearch()
    },
    until_date: function () {
      this.applySearch()
    },
    reload () {
      this.reload()
    },
  },
  computed: {
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    createdAt () {
      return this.logDetails
        ? timeUtils.formattedDate(this.logDetails.created_at)
        : ''
    },
    tableHeaders () {
      return [
        {
          text: 'Serialnr',
          value: 'serialnr'
        },
        {
          text: 'IP',
          value: 'client_ip'
        },
        {
          text: 'User-Agent',
          value: 'user_agent'
        },
        {
          text: 'Typ',
          value: 'type'
        },
        {
          text: 'Datum',
          value: 'created_at'
        },
      ]
    },
  },
}
</script>

<style lang="scss">
.v-icon {
  height: auto;
}

.v-messages.theme--dark.error--text {
  display: block;
  color: white !important;
}
</style>
