<template>
  <v-container fluid>
    <v-form ref="maintenanceForm" v-model="isValid">
      <v-row>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="from"
            :hint="dateFormat"
            :rules="[
              inputRules.requiredWithDateFormatHint,
              inputRules.dateFormat,
            ]"
            label="Von"
            outlined
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field
            v-model="until"
            :hint="dateFormat"
            :rules="[inputRules.dateFormat]"
            label="Bis"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col id="btn-container" cols="12" md="6">
          <v-textarea v-model="info" label="Text" outlined/>
        </v-col>
      </v-row>
      <v-row v-if="this.maintenance">
        <v-col cols="12" md="12">
          <v-switch
            v-model="active"
            :label="`Aktiv`"
            color="primary"
            inset
          ></v-switch>
        </v-col>
      </v-row>

      <v-btn class="ma-5" color="primary" @click="save">
        {{ btnName }}
      </v-btn>

      <v-btn v-if="maintenance" color="primary" @click="cancelEditing">
        Abbrechen
      </v-btn>
    </v-form>

    <v-data-table
      :footer-props="footerProps"
      :headers="tableHeaders"
      :height="wHeight * 0.5"
      :items="maintenances"
      :loading="isLoading ? 'loading' : false"
      class="elevation-1 devicestable"
      fixed-header
      loading-text="Daten werden geladen ..."
      @pagination="paginationChanged"
    >
      <template v-slot:item="row">
        <maintenance-list-row
          :maintenance="row.item"
          @delete="deleteMaintenance"
          @edit="editMaintenance"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import maintenanceApi from '@/api/maintenance'
import moment from 'moment'
import ErrorStore from '@/stores/ErrorStore'
import MaintenanceListRow from '@/components/Maintenance/MaintenanceListRow'
import timeUtil from '@/utils/time'
import QuestionStore from '@/stores/QuestionStore'

export default {
  name: 'Maintenance',
  components: { MaintenanceListRow },
  data: () => {
    return {
      maintenance: null,
      from: null,
      until: null,
      info: null,
      active: null,
      maintenances: [],
      wHeight: 0,
      footerProps: {
        'items-per-page-options': [20, 50, 100, -1],
      },
      isLoading: false,
      timeFormat: 'DD.MM.YYYY - HH:mm',
      isValid: false,
      btnName: 'Anlegen',
    }
  },
  mounted () {
    this.getMaintenances()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  computed: {
    dateFormat () {
      return `Datumformat ${this.timeFormat}`
    },
    inputRules () {
      return {
        dateFormat: (value) => {
          if (value) {
            const parsedTime = moment(value, this.timeFormat, true).isValid()
            if (!parsedTime) {
              return `Datum muss folgendem Format entsprechen: ${this.timeFormat}`
            }
            return true
          }
          return true
        },
        requiredWithDateFormatHint: (v) =>
          !!v ||
          `Dieses Feld ist verpflichtend, Datumformat ${this.timeFormat}`,
      }
    },
    tableHeaders () {
      return [
        {
          text: 'Von',
          value: 'start'
        },
        {
          text: 'Bis',
          value: 'end'
        },
        {
          text: 'Text',
          value: 'info'
        },
        {
          text: 'Aktiv',
          value: 'active'
        },
        { text: '' },
        { text: '' },
      ]
    },
  },
  methods: {
    cancelEditing () {
      this.from = null
      this.until = null
      this.info = null
      this.active = null
      this.maintenance = null
      this.btnName = 'Anlegen'
      this.$refs.maintenanceForm.reset()
    },
    getMaintenances () {
      maintenanceApi
        .getAll()
        .then((r) => {
          this.maintenances = r
        })
        .catch()
    },
    save () {
      if (this.$refs.maintenanceForm.validate()) {
        if (this.maintenance) {
          const data = { ...this.maintenance }
          data.start = this.from
          data.end = this.until ? this.until : null
          data.info = this.info
          data.active = this.active
          maintenanceApi
            .edit(data)
            .then(() => {
              this.cancelEditing()
              ErrorStore.showErrorMessage(
                'Das Wartungsfenster wurde erfolgreich bearbeitet',
                'Hinweis'
              )
              this.getMaintenances()
            })
            .catch((e) => {
              ErrorStore.showError(e)
            })
        } else {
          const data = {
            start: this.from,
            end: this.until,
            info: this.info,
          }
          maintenanceApi
            .create(data)
            .then(() => {
              this.cancelEditing()
              ErrorStore.showErrorMessage(
                'Das Wartungsfenster wurde erfolgreich erstellt',
                'Hinweis'
              )
              this.getMaintenances()
            })
            .catch((e) => {
              ErrorStore.showError(e)
            })
        }
      }
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
    editMaintenance (maintenance) {
      this.maintenance = maintenance
      this.from = timeUtil.formattedDate(maintenance.start)
      this.until = timeUtil.formattedDate(maintenance.end)
      this.info = maintenance.info
      this.active = maintenance.active
      this.btnName = 'Speichern'
    },
    deleteMaintenance (maintenance) {
      QuestionStore.poseQuestion(
        'Wollen Sie wirklich dieses Wartungsfenster löschen?',
        ['Ja', 'Nein']
      ).then((answer) => {
        if (answer === 0) {
          this.reallyDeleteMaintenance(maintenance)
        }
      })
    },
    reallyDeleteMaintenance (maintenance) {
      maintenanceApi
        .delete(maintenance)
        .then(() => {
          this.getMaintenances()
          ErrorStore.showErrorMessage(
            'Das Wartungsfenster wurde erfolgreich gelöscht',
            'Hinweis'
          )
        })
        .catch((e) => {
          ErrorStore.showError(e)
        })
    },
  },
}
</script>

<style scoped>
hr {
  color: #e30018;
}
</style>
