import base from './base'

export default {
  getAll () {
    return base.request('device/')
  },

  getDetails (id) {
    return base.request(`device/${id}`)
  },

  create (settings) {
    return base.request(`device/`, 'POST', settings)
  },

  update (id, settings) {
    return base.request(`device/${id}`, 'PUT', settings)
  },

  delete (id) {
    return base.request(`device/${id}`, 'DELETE')
  },

  updatePermissions (id, settings) {
    return base.request(`device/${id}/permissions`, 'PUT', settings)
  },

  updateExternalPermissions (id, settings) {
    return base.request(`device/${id}/external_permissions`, 'PUT', settings)
  },

  getReports (id) {
    return base.request(`device/${id}/reports`)
  },

  getAllReports () {
    return base.request('reports')
  },

  downloadExport (deviceIds) {
    return base.rawRequest(
      `device/export`,
      'POST',
      {
        device_ids: deviceIds,
      },
      true
    )
  },
  checkManualTimes (manualTimes) {
    return base.request('device/check_manual_times', 'POST', {
      manual_times: manualTimes,
    })
  },
}
