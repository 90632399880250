import base from './base'

export default {
  saveBMPhase (data) {
    return base.request('bayernmillionen/phase', 'POST', data)
  },

  getBMPhase () {
    return base.apiRequest('bayernmillionen', 'GET')
  },
}
