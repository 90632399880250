<template>
  <v-container color="#F5F5F5" fluid>
    <user-details
      v-model="detailsDialog"
      :user="userDetails"
      @reload="reload"
    />
    <v-row class="mb-1">
      <v-col>
        <v-text-field
          v-model="search"
          clear-icon="mdi-close-circle-outline"
          clearable
          flat
          hide-details
          label="Nutzer durchsuchen"
          prepend-inner-icon="fa-search"
          solo
        />
      </v-col>
      <v-col>
        <v-select
          v-model="selectedRole"
          :items="roles"
          flat
          hide-details
          label="-Alle Rollen-"
          solo
        />
      </v-col>

      <v-col v-if="userCanWrite">
        <v-layout>
          <v-card-text class="primary--text text-right font-weight-bold"
          >Neuer Nutzer
          </v-card-text
          >
          <v-btn
            color="primary"
            dark
            fab
            style="width: 50px; height: 50px"
            @click="createUser"
          >
            <!-- Style needed for display bug on firefox -->
            <v-icon class="white--text" style="display: inline-flex"
            >fa-upload
            </v-icon>
          </v-btn>
        </v-layout>
      </v-col>
    </v-row>
    <v-data-table
      :footer-props="footerProps"
      :headers="headers"
      :height="wHeight * 0.7"
      :items="displayUsers"
      :options.sync="tableOptions"
      class="elevation-1"
      fixed-header
      @pagination="paginationChanged"
    >
      <template v-slot:item="row">
        <tr style="cursor: pointer" @click="showDetails(row.item)">
          <td>{{ row.item.name }}</td>
          <td>{{ row.item.role.name.toUpperCase() }}</td>
          <td v-if="userCanWrite">
            <v-btn
              icon
              ripple
              style="float: right"
              @click.stop="deleteUser(row.item)"
            >
              <v-icon class="primary--text">fa-trash</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import userApi from '@/api/user'
import UserDetails from './UserDetails'
import AuthStore from '@/stores/AuthStore'
import QuestionStore from '@/stores/QuestionStore'
import authApi from '@/api/auth'

export default {
  name: 'all-users',
  components: {
    UserDetails,
  },
  data () {
    return {
      users: [],
      search: '',
      detailsDialog: false,
      userDetails: null,
      selectedRole: null,
      roles: [
        '-Alle Rollen-',
        'Administrator',
        'Qualitätssicherung',
        'Vertrieb',
        'Redaktion',
      ],
      headers: [
        {
          text: 'Nutzer',
          value: 'name',
        },
        {
          text: 'Rolle',
          value: 'role.role_id',
        },
        {
          text: '',
        },
      ],
      footerProps: {
        'items-per-page-options': [20, 50, 100, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
        sortBy: ['role.role_id'],
      },
      wHeight: 0,
      userCanWrite: AuthStore.state.hasWriteAccess(),
    }
  },
  methods: {
    showDetails (user) {
      this.userDetails = user
      this.detailsDialog = true
    },
    createUser () {
      this.userDetails = null
      this.detailsDialog = true
    },
    reload () {
      authApi.reauth().then(() => {
        userApi.getAll().then((a) => {
          if (!this.userCanWrite) {
            this.users = a.filter(
              (u) => u.email === AuthStore.state.user.email
            )
          } else {
            this.users = a
          }
        })
      })
    },
    deleteUser (user) {
      QuestionStore.poseQuestion(
        `Nutzer ${user.name} wirklich löschen? Dies kann nicht rückgängig gemacht werden`,
        ['Ja', 'Nein']
      ).then((answer) => {
        if (answer === 0) {
          this.reallyDeleteUser(user)
        }
      })
    },
    reallyDeleteUser (user) {
      userApi.delete(user).then(() => {
        this.reload()
      })
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
  },

  mounted () {
    this.reload()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  computed: {
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    displayUsers () {
      if (
        this.selectedRole != null &&
        this.selectedRole !== this.roles[0] &&
        this.search !== ''
      ) {
        const selectedRole = this.selectedRole.toLowerCase()
        const search = this.search.toLowerCase()
        return this.users
          .filter((u) => {
            u.role.name.toLowerCase().includes(selectedRole)
          })
          .filter((u) => {
            u.email.toLowerCase().includes(search) ||
            u.name.toLowerCase().includes(search)
          })
      } else if (
        this.selectedRole != null &&
        this.selectedRole !== this.roles[0]
      ) {
        const selectedRole = this.selectedRole.toLowerCase()
        return this.users.filter((u) =>
          u.role.name.toLowerCase().includes(selectedRole)
        )
      } else if (this.search === '') {
        return this.users
      } else {
        const search = this.search.toLowerCase()
        return this.users.filter((a) => {
          return (
            a.email.toLowerCase().includes(search) ||
            a.name.toLowerCase().includes(search)
          )
        })
      }
    },
  },
  watch: {
    windowHeight (height) {
      this.wHeight = height
    },
  },
}
</script>

<style lang="scss" scoped></style>
