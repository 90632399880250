<template>
  <div>
    <device-details
      :key="deviceDetails ? deviceDetails.device_id : Date.now()"
      v-model="detailsDialog"
      :device="deviceDetails"
      @reload="this.reload"
    />
    <csv-upload-dialog v-model="uploadDialog" @reload="reload"/>
    <v-sheet class="pa-3 primary lighten-2">
      <v-text-field
        v-model="search"
        clear-icon="mdi-close-circle-outline"
        clearable
        dark
        flat
        hide-details
        label="Annahmestellen durchsuchen"
        prepend-inner-icon="fa-search"
        solo-inverted
      ></v-text-field>
      <v-select
        v-model="selectedGroupId"
        :items="selecteAbleGroups"
        dark
        item-text="name"
        item-value="group_id"
      ></v-select>
      <v-select
        v-model="astFilter"
        :items="bindAstFilters"
        dark
        item-text="name"
        item-value="astFilter"
      ></v-select>
    </v-sheet>
    <v-container fluid>
      <v-card>
        <v-container>
          <v-btn class="primary" @click="downloadExport"
          >CSV Export
            <v-icon right>fa-file-export</v-icon>
          </v-btn>
        </v-container>
      </v-card>

      <v-data-table
        :footer-props="footerProps"
        :headers="tableHeaders"
        :height="wHeight * 0.5"
        :items="displayDevices"
        :loading="isLoading ? 'loading' : false"
        :options.sync="tableOptions"
        class="elevation-1 devicestable"
        fixed-header
        loading-text="Daten werden geladen ..."
        @pagination="paginationChanged"
      >
        <template v-slot:item="row">
          <device-list-row
            :device="row.item"
            :show-trash="userCanWrite"
            @click="showDetails"
            @delete="deleteDevice"
            @action-click="showReports"
          />
        </template>
      </v-data-table>

      <v-speed-dial
        v-if="userCanWrite"
        v-model="fab"
        bottom
        direction="top"
        fixed
        right
        style="margin-bottom: 50pt"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator>
          <v-btn v-model="fab" color="primary" dark fab>
            <v-icon>fa-plus</v-icon>
          </v-btn>
        </template>
        <v-btn color="green" dark fab small @click="createDevice">
          <v-icon>fa-edit</v-icon>
        </v-btn>
        <v-btn color="indigo" dark fab small @click="startUpload">
          <v-icon>fa-upload</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-container>
  </div>
</template>

<script>
import deviceApi from '@/api/device'
import groupsApi from '@/api/groups'
import DeviceDetails from './DeviceDetails'
import QuestionStore from '@/stores/QuestionStore'
import AuthStore from '@/stores/AuthStore'
import CsvUploadDialog from '@/components/Devices/CSVUploadDialog'
import DeviceListRow from '@/components/Devices/DeviceListRow'

export default {
  name: 'all-devices',
  components: {
    DeviceListRow,
    CsvUploadDialog,
    DeviceDetails
  },
  data () {
    return {
      devices: [],
      search: '',
      detailsDialog: false,
      deviceDetails: null,
      uploadDialog: false,
      fab: false,
      groups: [],
      wHeight: 0,
      selectedGroupId: null,
      footerProps: {
        'items-per-page-options': [20, 50, 100, -1],
      },
      tableOptions: {
        itemsPerPage: this.itemsPerPage(),
        sortBy: ['customAnnahmestellennr'],
      },
      astFilter: AstFilters.noFilter,
      autoOpenedPopup: false,
      loading: false,
    }
  },
  methods: {
    showDetailsForId (id) {
      deviceApi.getDetails(id).then((details) => {
        this.deviceDetails = details
        this.detailsDialog = true
      })
    },
    showDetails (device) {
      this.showDetailsForId(device.device_id)
    },
    showReports (device) {
      this.$router.push(`/device/${device.device_id}/reports`)
    },
    deleteDevice (device) {
      var deleteMessage
      if (
        device.annahmestelle !== null &&
        device.annahmestelle.annahmestellennr !== ''
      ) {
        deleteMessage = `Gerät mit der Annahmestelle ${device.annahmestelle.annahmestellennr} wirklich löschen? Dies kann nicht rückgängig gemacht werden`
      } else {
        deleteMessage = `Gerät ${device.serialnumber} wirklich löschen? Dies kann nicht rückgängig gemacht werden`
      }
      QuestionStore.poseQuestion(deleteMessage, ['Ja', 'Nein']).then(
        (answer) => {
          if (answer === 0) {
            this.reallyDeleteDevice(device)
          }
        }
      )
    },
    reallyDeleteDevice (device) {
      deviceApi.delete(device.device_id).then(() => {
        this.reload()
      })
    },

    reload () {
      this.loading = true
      deviceApi.getAll().then((a) => {
        this.devices = a
        if (this.$route.params.id && !this.autoOpenedPopup) {
          this.showDetailsForId(this.$route.params.id)
          this.autoOpenedPopup = true
        }
        this.loading = false
      })

      groupsApi.getAll().then((g) => {
        this.groups = g
      })
    },

    createDevice () {
      this.deviceDetails = null
      this.detailsDialog = true
    },

    startUpload () {
      this.uploadDialog = true
    },

    downloadExport () {
      const deviceIds = this.displayDevices.map((d) => {
        return d.device_id
      })
      deviceApi
        .downloadExport(deviceIds)
        .then((r) => {
          return r.arrayBuffer()
        })
        .then((r) => {
          const url = window.URL.createObjectURL(new Blob([r]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Geräte.csv')
          document.body.appendChild(link)
          link.click()
        })
    },
    itemsPerPage () {
      return parseInt(localStorage.getItem('itemsPerPage')) || 50
    },
    paginationChanged (pagination) {
      localStorage.setItem('itemsPerPage', pagination.itemsPerPage)
    },
  },
  mounted () {
    this.reload()
    this.wHeight = this.$vuetify.breakpoint.height
  },
  watch: {
    /* When the user hits "Speichern" there will be 2 nested Scrollbars shown on Chrome browser.
         To stop this behavior we need to set this property to hidden, once the dialog shows up */
    detailsDialog: function (val) {
      document.querySelector('html').style.overflow = val ? 'hidden' : null
    },
    windowHeight (height) {
      this.wHeight = height
    },
  },
  computed: {
    windowHeight () {
      return this.$vuetify.breakpoint.height
    },
    isLoading () {
      return this.loading
    },
    selecteAbleGroups () {
      let allGroups = {
        group_id: null,
        name: '- Alle Gruppen -',
      }

      let noGroup = {
        group_id: -1,
        name: '- Keine Gruppe -',
      }

      return [allGroups].concat(this.groups).concat([noGroup])
    },

    bindAstFilters () {
      let noAstsFilters = {
        astFilter: AstFilters.noFilter,
        name: '- Kein Filter -',
      }

      let astsWithValueFilter = {
        astFilter: AstFilters.onlyWithAST,
        name: 'Nur ASTn anzeigen',
      }

      let externalAstsFilter = {
        astFilter: AstFilters.onlyWithExternalAST,
        name: 'Nur externe ASTn anzeigen',
      }

      return [noAstsFilters, astsWithValueFilter, externalAstsFilter]
    },

    displayDevices () {
      let devices = this.devices
      if (this.astFilter !== AstFilters.noFilter) {
        switch (this.astFilter) {
          case AstFilters.onlyWithAST:
            devices = devices.filter((d) => {
              return (
                d.annahmestelle !== null &&
                d.annahmestelle.annahmestellennr !== ''
              )
            })
            break
          case AstFilters.onlyWithExternalAST:
            devices = devices.filter((d) => {
              return (
                d.annahmestelle !== null &&
                d.annahmestelle.annahmestellennr !== '' &&
                !d.annahmestelle.annahmestellennr.startsWith('000')
              )
            })
            break
        }
      }
      if (this.selectedGroupId !== null) {
        if (this.selectedGroupId === -1) {
          devices = devices.filter((d) => {
            return d.group_id === null
          })
        } else {
          devices = devices.filter((d) => {
            return d.group_id === this.selectedGroupId
          })
        }
      }

      if (this.search !== '') {
        const search = this.search.toLowerCase()
        devices = devices.filter((a) => {
          return (
            (a && a.serialnumber.toLowerCase().includes(search)) ||
            (a &&
              a.annahmestelle &&
              a.annahmestelle.annahmestellennr
                .toLowerCase()
                .includes(search)) ||
            (a && a.ast_info && a.ast_info.toLowerCase().includes(search))
          )
        })
      }

      // Attach extra annahmestellennr property for custom sorting
      devices = devices.map((d) => {
        // Add a ridiculously lexicographically high value if AST is empty, so those get sorted to the end
        d.customAnnahmestellennr =
          d.annahmestelle &&
          d.annahmestelle.annahmestellennr &&
          d.annahmestelle.annahmestellennr.trim()
            ? d.annahmestelle.annahmestellennr
            : 'ZZZZZZZZZZZZZZZ'
        return d
      })

      return devices
    },

    userCanWrite () {
      return AuthStore.state.hasWriteAccess()
    },

    tableHeaders () {
      return [
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: '',
          value: null,
          sortable: false
        },
        {
          text: `Geräte (${this.displayDevices.length})`,
          value: 'serialnumber',
        },
        {
          text: 'Annahmestelle',
          value: 'customAnnahmestellennr'
        },
        {
          text: 'AST Zusatzinfo',
          value: 'ast_info'
        },
        {
          text: 'IP-Adresse',
          value: 'ip'
        },
        {
          text: 'Gruppe',
          value: 'group.name'
        },
        {
          text: 'Manuelle Öffnungszeiten',
          value: 'manual_opening_times'
        },
        {
          text: 'Letzte Anfrage',
          value: 'last_access'
        },
        {
          text: 'Absturzberichte',
          value: 'report_count'
        },
      ]
    },
  },
}
const AstFilters = {
  noFilter: -1,
  onlyWithAST: 0,
  onlyWithExternalAST: 1,
}
</script>

<style lang="scss" scoped>
.v-icon {
  height: auto;
}
</style>
<style lang="css">
.devicestable .v-data-footer {
  padding-right: 50pt;
}
</style>
