import base from './base'

export default {
  getAll () {
    return base.request('documents', 'GET')
  },

  postDoc (filename, key, title, category, publishAt, publishUntil) {
    return base.request('documents', 'POST', {
      filename,
      key,
      title,
      category,
      publish_at: publishAt,
      publish_until: publishUntil,
    })
  },

  updateDoc (filename, publishAt, publishUntil) {
    return base.request('documents', 'PUT', {
      filename: filename,
      publish_at: publishAt,
      publish_until: publishUntil,
    })
  },

  async delete (document) {
    return base.request(`documents/${document.document_id}`, 'DELETE')
  },
}
