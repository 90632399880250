<template>
  <v-form>
    <v-layout style="padding-left: 10%; padding-right: 10%">
      <v-flex>
        <v-card class="mt-4">
          <v-container>
            <v-row
              v-if="
                !(bayernmillionen && bayernmillionen.manual_activated) &&
                this.phase !== -1
              "
            >
              <v-col cols="2">
                <v-subheader>Phase</v-subheader>
              </v-col>
              <v-col cols="2">
                <v-subheader>{{ this.phase }}</v-subheader>
              </v-col>
            </v-row>
            <v-row
              v-if="
                immutablePayinEndDate &&
                !(bayernmillionen && bayernmillionen.manual_activated)
              "
            >
              <v-col cols="2">
                <v-subheader>Initialer Abgabeschluss</v-subheader>
              </v-col>
              <v-col cols="2">
                <v-subheader>{{ immutablePayinEndDate }}</v-subheader>
              </v-col>
            </v-row>
            <v-row
              v-if="
                newPayinEndDate &&
                !(bayernmillionen && bayernmillionen.manual_activated)
              "
            >
              <v-col cols="2">
                <v-subheader>Neuer Abgabeschluss</v-subheader>
              </v-col>
              <v-col cols="2">
                <v-subheader>{{ newPayinEndDate }}</v-subheader>
              </v-col>
            </v-row>
            <v-row
              v-if="
                lastYearPayinEndDate &&
                !(bayernmillionen && bayernmillionen.manual_activated)
              "
            >
              <v-col cols="2">
                <v-subheader>Abgabeschluss letztes Jahres</v-subheader>
              </v-col>
              <v-col cols="2">
                <v-subheader>{{ lastYearPayinEndDate }}</v-subheader>
              </v-col>
            </v-row>
            <v-text-field
              v-model="drawDate"
              :disabled="!authState.hasWriteAccess()"
              :hint="`Format: '${timeFormat}'`"
              :rules="[rules.datetimeformatIfNotEmpty]"
              label="Drawdate"
              outlined
            />
            <v-checkbox
              v-model="manual_activated"
              :disabled="!authState.hasWriteAccess()"
              label="Manuelle Phasenumschaltung"
              @mousedown="onCheckboxClicked"
            ></v-checkbox>
            <v-select
              v-if="manual_activated"
              v-model="phase"
              :disabled="!authState.hasWriteAccess()"
              :items="items"
              label="Phase"
            />

            <v-text-field
              v-if="manual_activated"
              v-model="mutableFinalPayinEndDate"
              :disabled="!authState.hasWriteAccess()"
              :hint="`Format: '${timeFormat}'`"
              :rules="[rules.required, rules.datetimeformat]"
              label="Initialer Abgabeschluss"
              outlined
            />
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout>
      <v-spacer/>
      <v-flex v-if="manual_activation_changed" class="pt-3 primary--text" xs7>
        Änderungen sind noch nicht übernommen. Bitte drücken Sie auf
        "Übernehmen", wenn Sie Umschaltung wünschen.
      </v-flex>
      <v-flex class="pt-2" style="padding-right: 10%" xs3>
        <v-btn
          v-if="authState.hasWriteAccess()"
          :class="[checkInputs ? 'secondary primary--text' : 'grey']"
          block
          class="font-weight-bold"
          large
          @click="confirmSaving"
        >
          ‎‏‏‎Übernehmen‎‏‏‎
        </v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import moment from 'moment'
import BMApi from '@/api/bayernmillionen'
import QuestionStore from '@/stores/QuestionStore'
import ErrorStore from '@/stores/ErrorStore'
import AuthStore from '@/stores/AuthStore'
import timeUtils from '@/utils/time'

export default {
  name: 'bayernmillionen',
  components: {},
  data () {
    return {
      manual_activated: false,
      manual_activation_changed: false,
      items: ['1', '2', '3', '4'],
      phase: -1,
      mutableFinalPayinEndDate: '',
      immutablePayinEndDate: '',
      newPayinEndDate: '',
      lastYearPayinEndDate: '',
      drawDate: '',
      authState: AuthStore.state,
      timeFormat: 'DD.MM.YYYY - HH:mm',
      bayernmillionen: null,
      rules: {
        required: (value) => !!value || 'erfolderlich',
        datetimeformat: (value) => {
          var parsedTime = moment(value, this.timeFormat, true).isValid()
          if (!parsedTime) {
            return `Datum muss folgendem Format entsprechen: ${this.timeFormat}`
          }
          return true
        },
        datetimeformatIfNotEmpty: (value) => {
          if (value) {
            var parsedTime = moment(value, this.timeFormat, true).isValid()
            if (!parsedTime) {
              return `Datum muss folgendem Format entsprechen: ${this.timeFormat}`
            }
            return true
          }
          return true
        },
      },
    }
  },
  methods: {
    onCheckboxClicked () {
      if (this.manual_activated === true) {
        this.confirmSwitchingToAutomatic()
      } else {
        this.confirmSwitchingToManual()
      }
      return false
    },
    confirmSaving () {
      if (this.checkInputs) {
        QuestionStore.poseQuestion(
          'Wollen Sie wirklich Ihre Angaben speichern ?',
          ['Ja', 'Nein']
        ).then((answer) => {
          if (answer === 0) {
            this.savePhase()
          }
        })
      } else {
        ErrorStore.showErrorMessage('Bitte prüfen Sie Ihre Angaben!')
      }
    },
    savePhase () {
      var data = {}
      if (!this.manual_activated) {
        data = {
          draw_date: this.drawDate,
        }
      } else {
        data = {
          draw_date: this.drawDate,
          manual_activated: this.manual_activated,
          phase: this.phase,
          final_payin_end_date: this.mutableFinalPayinEndDate,
        }
      }
      BMApi.saveBMPhase(data)
        .then((r) => {
          ErrorStore.showMessage('Erfolgreich gespeichert', 'Status')
          this.manual_activation_changed = false
          this.reload()
        })
        .catch((e) => {
          ErrorStore.showErrorMessage(
            'Ein Fehler ist aufgetreten, bitte prüfen Sie Ihre Verbindung und versuchen Sie es erneut!'
          )
        })
    },
    reload () {
      BMApi.getBMPhase().then((a) => {
        this.bayernmillionen = a
        this.phase = a.phase
        this.manual_activated = a.manual_activated
        this.mutableFinalPayinEndDate = timeUtils.formattedDate(
          a.final_payin_end_date
        )
        this.immutablePayinEndDate = timeUtils.formattedDate(
          a.final_payin_end_date
        )
        this.newPayinEndDate = timeUtils.formattedDate(a.new_payin_end_date)
        this.lastYearPayinEndDate = timeUtils.formattedDate(
          a.last_year_payin_end_date
        )
        this.drawDate = timeUtils.formattedDate(a.draw_date)
      })
    },
    confirmSwitchingToManual () {
      QuestionStore.poseQuestion(
        'Möchten Sie die Umschaltung der Bayernmillionen-Phasen auf manuell ändern?',
        ['OK', 'Abbrechen'],
        undefined,
        'Hinweis: Die Phasen werden in diesem Fall dann nicht mehr automatisch vom Cloud-Server umgestellt und ' +
        'auch der Abgabeschluss wird nicht mehr automatisch aus der Schnittstelle von LOTTO Bayern übernommen!'
      ).then((answer) => {
        if (answer === 0) {
          this.manual_activated = true
          this.manual_activation_changed = true
        } else {
          this.manual_activated = false
        }
      })
    },
    confirmSwitchingToAutomatic () {
      QuestionStore.poseQuestion(
        'Möchten Sie die Umschaltung der Bayernmillionen-Phasen auf automatisch ändern?',
        ['OK', 'Abbrechen']
      ).then((answer) => {
        if (answer === 0) {
          this.manual_activated = false
          this.manual_activation_changed = true
        } else {
          this.manual_activated = true
        }
      })
    },
  },
  mounted () {
    this.reload()
  },
  computed: {
    checkInputs () {
      const validDrawDate = this.drawDate
        ? moment(this.drawDate, this.timeFormat, true).isValid()
        : true
      if (this.manual_activated) {
        const validFinalPayinEnddate = moment(
          this.mutableFinalPayinEndDate,
          this.timeFormat,
          true
        ).isValid()
        const validPhase = this.phase !== -1
        return validFinalPayinEnddate && validDrawDate && validPhase
      } else {
        return validDrawDate
      }
    },
  },
}
</script>
<style>
#styled-input {
  width: 200px;
}
</style>
