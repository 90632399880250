<template>
  <v-list-item @click="clicked">
    <v-flex xs5>
      <v-layout>
        <v-list-item-avatar>
          <v-icon class="primary white--text">fa-box-open</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
          >Version: {{ item.version_name }}
          </v-list-item-title
          >
          <v-list-item-subtitle
          >Code: {{ item.version_code }} -
            {{ item.created_at }}
          </v-list-item-subtitle
          >
        </v-list-item-content>
      </v-layout>
    </v-flex>

    <v-flex xs4>
      <v-layout>
        <v-list-item-content>
          <v-list-item-action-text class="body-1 black--text">{{
              this.groupNames
            }}
          </v-list-item-action-text>
        </v-list-item-content>
      </v-layout>
    </v-flex>

    <v-flex xs3>
      <v-layout>
        <v-list-item-content>
          <a :href="item.download" target="_blank">
            <v-btn class="primary">
              <v-icon class="pl-1" dense>fa-file-export</v-icon>
            </v-btn>
          </a>
        </v-list-item-content>
      </v-layout>
    </v-flex>

    <v-flex>
      <v-layout style="float: right">
        <v-btn color="primary" @click="distributeAppVersion"
        >An Gruppen verteilen
        </v-btn
        >
        <v-btn v-if="showTrash" icon ripple @click.stop="deleteVersion">
          <v-icon class="primary--text">fa-trash</v-icon>
        </v-btn>
      </v-layout>
    </v-flex>
  </v-list-item>
</template>
<script>
import QuestionStore from '@/stores/QuestionStore'
import appsApi from '@/api/apps'
import errorStore from '@/stores/ErrorStore'

export default {
  name: 'app-version-list-tile',
  data () {
    return {
      groups: [],
    }
  },
  props: {
    item: {},
    showTrash: Boolean,
    heighestVersionId: Number,
    showDistribute: Boolean,
  },
  watch: {
    item () {
      this.getGroups()
    },
  },
  mounted () {
    this.getGroups()
  },
  computed: {
    groupNames () {
      return this.groups
        .map((g) => {
          return g.name
        })
        .join(', ')
    },
  },
  methods: {
    getGroups () {
      appsApi
        .getGroupsWithAppVersion(this.item.version_id)
        .then((groups) => {
          this.groups = groups
        })
        .catch((error) => {
          errorStore.showError(error)
        })
    },
    distributeAppVersion () {
      this.$emit('ondistribute', this.item)
    },
    deleteVersion () {
      QuestionStore.poseQuestion(
        'Version wirklich löschen? Dies kann nicht rückgängig gemacht werden',
        ['Ja', 'Nein']
      ).then((answer) => {
        if (answer === 0) {
          this.reallyDeleteVersion(this.item)
        }
      })
    },
    reallyDeleteVersion (version) {
      appsApi
        .deleteVersion(version.version_id)
        .then(() => {
          this.$emit('reload')
        })
        .catch((err) => {
          errorStore.showError(err)
        })
    },
    clicked () {
      this.$emit('click', this.item)
    },
  },
}
</script>
